import React from 'react';
import { Helmet } from 'react-helmet';

const BlogPost = ({ title, metaDescription, sections }) => {
  return (
    <article className="blog-container">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      <h1 className="blog-title">{title}</h1>

      <div className="blog-content">
        {sections.map((section, index) => (
          <section key={index} className="blog-section">
            <h2>{section.title}</h2>
            <p>{section.content}</p>
          </section>
        ))}
      </div>
    </article>
  );
};

export default BlogPost;
