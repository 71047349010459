// voiceTranscriptionService.js
const io = require('socket.io-client');

class VoiceTranscriptionService {
  constructor() {
    this.socket = io(`${process.env.REACT_APP_API_BASE_URL}`, { transports: ['websocket', 'polling'] });
    this.setupSocketListeners();
  }

  setupListeners({ onTranscript }) {
    this.socket.on('connect', () => {
      console.log('Connected to WebSocket server');
    });

    this.socket.on('transcript', (transcript) => {
      if (onTranscript) onTranscript(transcript);
    });

    this.socket.on('disconnect', () => {
      console.log('Disconnected from WebSocket server');
    });
  }
  removeListeners() {
    this.socket.removeListener('connect');
    this.socket.removeListener('transcript');
    this.socket.removeListener('disconnect');
  }

  // Inside VoiceTranscriptionService
emitLanguageChange(language, model) {
  if (this.socket && this.socket.connected) {
    this.socket.emit('changeLanguage', { language, model });
  } else {
    console.error('Socket not connected');
  }
}
// Inside VoiceTranscriptionService
emit(event, data) {
  if (this.socket && this.socket.connected) {
    this.socket.emit(event, data);
  } else {
    console.error('Socket not connected');
  }
}

  
  setupSocketListeners() {
    this.socket.on('connect', () => {
      console.log('Connected to WebSocket server');
    });

    this.socket.on('transcript', (transcript) => {
      console.log('Transcript received:', transcript);
      // Handle transcript here or pass it to your React component via a callback
    });

    this.socket.on('disconnect', () => {
      console.log('Disconnected from WebSocket server');
    });
  }

  isSocketReady() {
    return this.socket && this.socket.connected;
}

reinitializeConnection() {
    if (this.socket) {
        this.socket.disconnect();
        this.socket.connect();
    }
}

reinitializeDeepgram() {
  this.socket.emit('reinitializeDeepgram');
}

initializeConnection() {
  this.socket.connect();
  this.setupSocketListeners();
}
resetConnection() {
  this.socket.disconnect();
}

getSocketId() {
  return this.socket ? this.socket.id : null;
}
  sendAudioData(data) {
    this.socket.emit('packet-sent', data);
  }
  reinitializeConnection(language, model) {
    console.log('Reinitializing Deepgram connection');
    this.socket.disconnect();
  
    // Reconnect and then emit an event to the backend
    this.socket.connect();
    this.socket.on('connect', () => {
      console.log('Socket reconnected. Emitting language change event.');
      this.socket.emit('languageChanged', { language, model });
    });
    // Assuming 'socket' is your initialized socket.io client
this.socket.on('languageChanged', () => {
  console.log("Language change detected, reinitializing Deepgram connection...");
  this.socket.emit('reinitializeDeepgram');
});


  }
  
}

export default new VoiceTranscriptionService();