// Signup.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import './Signup.css'; // Import the CSS file
import { useTranslation } from 'react-i18next';
const Signup = () => {
    const navigate = useNavigate();
    const [credentials, setCredentials] = useState({ username: '', email: '', password: '' });
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const { t } = useTranslation();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setCredentials(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Send a POST request to the signup endpoint
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/register`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(credentials),
            });

            if (response.ok) {
                setMessage(t('register_success_message')  );
                setError('');
            } else {
                setMessage('');
                setError(t('register_failed_message') );
            }
        } catch (error) {
            console.error('An error occurred during signup', error);
            setMessage('');
            setError(t('register_failed_message') );
        }
    };
    return (
        <form onSubmit={handleSubmit}>
            
            <input 
                type="text" 
                name="username" 
                value={credentials.username} 
                onChange={handleChange} 
                placeholder={t('username')}   
                required 
            />
            <input 
                type="email" 
                name="email" 
                value={credentials.email} 
                onChange={handleChange} 
                placeholder={t('email')}  
                required 
            />
            <input 
                type="password" 
                name="password" 
                value={credentials.password} 
                onChange={handleChange} 
                placeholder={t('password')}   
                required 
            />
            <button className="signupbutton" type="submit">{t('signup')}  </button>
            {message && <p className="success-message">{message}</p>}
            {error && <p className="error-message">{error}</p>}
        </form>
    );
};

export default Signup;
