import React from 'react';
import './Subpage1.css';
import { Link } from 'react-router-dom';
const Subpage3 = () => {
  return (
    <div className="subpage-container">
      <h1 className="subpage-header">Optimizing Medical Documentation with Hynote</h1>
      <section className="subpage-section">
        <h2 className="subpage-subheader">Medical Professionals, Streamline Your Workflow!</h2>
        <p className="subpage-text">
          Hynote presents an innovative solution for medical professionals striving to streamline their clinical documentation process. Its state-of-the-art voice transcription service accurately captures complex medical terminology, allowing doctors, nurses, and healthcare workers to dictate patient information, which is then converted into structured, concise summaries.
        </p>
      </section>
      
      <section className="subpage-section">
        <h2 className="subpage-subheader">Enhanced Accuracy and Time Management</h2>
        <p className="subpage-text">
          With Hynote, medical dictations are transcribed with high precision, minimizing the risk of errors and ensuring that patient records are both accurate and reliable. By significantly reducing the time spent on documentation, healthcare providers can allocate more time to patient care, thus improving outcomes and enhancing the overall quality of service.
        </p>
      </section>

      <section className="subpage-section">
        <h2 className="subpage-subheader">Ease of use</h2>
        <p className="subpage-text">
          Hynote seamlessly integrates with existing Electronic Health Record (EHR) systems, by providing very useful summaries of spoken dictation. It's easy to use and you can trust entering your Hynote generated notes in
          any EHR. Please note however, this is all entirely up to your employer and their policies with Artifical Intelligence.
        </p>
      </section>
      
      <section className="subpage-section">
        <h2 className="subpage-subheader">Collaboration in Care Teams</h2>
        <p className="subpage-text">
          Collaboration is key in healthcare settings. Hynote's collaborative platform enables multiple members of the care team to access and contribute to patient notes, fostering a collaborative approach to patient management. This real-time sharing and updating of patient data ensures that all team members are informed and can make the best decisions for patient care.
        </p>
      </section>
      
      {/* Link to more use cases or back to the main use cases page */}
      <div className="more-use-cases">
        <Link to="/use-cases" className="more-use-cases-link">Back to Use Cases</Link>
      </div>
    </div>
  );
};

export default Subpage3;
