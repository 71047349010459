import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

const EmailConfirmation = () => {
    const { token } = useParams(); // Extract the token from URL

    useEffect(() => {
        // Correctly format the URL for the fetch request
        const url = `${process.env.REACT_APP_API_BASE_URL}/confirm/${token}`;

        // Send a request to the backend on component mount
        fetch(url)
            .then(response => {
                if (response.ok) {
                    console.log('Email confirmed successfully');
                    // Redirect or show success message
                } else {
                    console.log('Error confirming email');
                    // Handle error or show error message
                }
            })
            .catch(error => console.error('Error:', error));
    }, [token]);

    return (
        <div>
          <h2>Success! You may now login.</h2>
          <p><Link to="/login">Click here</Link> to login.</p>
        </div>
      );
    };

export default EmailConfirmation;
