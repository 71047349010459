import React from 'react';
import './Subpage1.css';
import { Link } from 'react-router-dom';
const Subpage1 = () => {
  return (
    <div className="subpage-container">
      <h1 className="subpage-header">Content Creators WILL Benefit From Hynote</h1>
      <section className="subpage-section">
        <h2 className="subpage-subheader">Content Creators, look no further!</h2>
        <p className="subpage-text">
          If you're part of the content creation sphere (Youtube, TikTok, Instagram, Meta/Facebook),
          then you will find great use out of Hynote. If you ever wanted to make a lot of videos at once,
          but did not have the time to write scripts for each and every one, Hynote will save you hours
          and potentially weeks in the long run of having to fully write scripts for videos.
          
          Users have the option to choose how their script is generated, either from their existing rough
          draft, or by using keywords, for a more automated approach. The latter will save lots of time,
          however the former is best if you want your content to be more human than AI.
        </p>
      </section>
      
      <section className="subpage-section">
        <h2 className="subpage-subheader">Automated Script Writing</h2>
        <p className="subpage-text">
          Our automated Youtube script writer will save you from countless hours of the headache of coming up with video topic ideas,
          and not only that, but ALSO writing the script for the videos as well! It not only can browse the web to see what is trending and make a randomly generated video based on news content, but can also come 
          up with a large number of other topics as well. This saves
          valuable research time as well as writing and editing time! Plus, the final output looks good to boot!
        </p>
      </section>

      <section className="subpage-section">
        <h2 className="subpage-subheader">Seamless Integration with Social Platforms</h2>
        <p className="subpage-text">
          Hynote understands the fast-paced nature of social media. That's why it's equipped with features
          that allow for seamless integration with your favorite social platforms. Effortlessly export your scripts
          into formats that are compatible with Youtube descriptions, TikTok captions, and more. With Hynote,
          you're not just saving time on scriptwriting; you're streamlining your entire content production process.
        </p>
      </section>
      
      <section className="subpage-section">
        <h2 className="subpage-subheader">Engage Your Audience Like Never Before</h2>
        <p className="subpage-text">
          With scripts that are tailored to your style and the current trends, engage your audience with content
          that resonates. Hynote's AI doesn't just generate scripts—it generates connections. Let the power of AI
          bring you closer to your community, with content that speaks to them directly in the language they love.
        </p>
      </section>
      <div className="more-use-cases">
        <Link to="/use-cases" className="more-use-cases-link">Back to Use Cases</Link>
      </div>
    </div>
  );
};

export default Subpage1;
