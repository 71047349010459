import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import './MarkdownCSS.css';

const MarkdownDisplay = ({ content }) => {
  return (
    <div className="markdown-container">
      <ReactMarkdown className='markdown-table' rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
        {content}
      </ReactMarkdown>
    </div>
  );
};

export default MarkdownDisplay;
