import React, { useEffect } from 'react';

function CheckoutSuccess() {
    useEffect(() => {
        // Extract the session ID from the URL's query parameters
        const urlParams = new URLSearchParams(window.location.search);
        const sessionId = urlParams.get('sessionId'); // Make sure this matches the key in the URL
        console.log('Session ID:', sessionId);

        const updateUserInformation = async () => {
            if (!sessionId) {
                console.error('No session ID available');
                return;
            }

            console.log('Attempting to update user information with session ID:', sessionId);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/checkout-success`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ sessionId }),
                });

                console.log('Response received:', response);

                if (response.ok) {
                    console.log('User information updated successfully');
                } else {
                    console.error('Failed to update user information');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        if (sessionId) {
            updateUserInformation();
        }
    }, []);

    return (
        <div>
            <h1>Checkout Successful</h1>
            <p>Your payment was successful! We are setting up your account.</p>
        </div>
    );
}

export default CheckoutSuccess;
