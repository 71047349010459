import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-container">
            <h1 className="privacy-title">Privacy Policy</h1>
            <div className="privacy-content">
                <p><strong>Effective Date:</strong> 12/2/23</p>
                <p>This Privacy Policy describes how Hynote ("we", "us", or "our") collects, uses, and discloses information from our users ("you") of Hynote.co ("Service").</p>

                <h2>Information Collection</h2>
                <p>We collect information that you provide directly to us when you use our Service. This may include your name, email address, and any other information you choose to provide. We also automatically collect certain information when you use our Service, such as your IP address and device information.</p>

                <h2>Use of Information</h2>
                <p>The information we collect is used to provide, maintain, and improve our Service. We do not use this information for any other purpose, nor do we share it with third parties for their marketing purposes.</p>

                <h2>Data Sharing and Disclosure</h2>
                <p>We do not sell user data. We may share your information with third parties in the following circumstances:</p>
                <ul>
                    <li>With service providers who assist us in operating our Service.</li>
                    <li>If required by law, such as in response to a subpoena or court order.</li>
                    <li>If we believe it’s necessary to prevent harm or injury, such as in the case of fraud prevention or to protect our legal rights.</li>
                </ul>

                <h2>Data Security</h2>
                <p>We implement reasonable security measures to protect your information from unauthorized access, alteration, or destruction.</p>

                <h2>Your Rights</h2>
                <p>You have the right to access, correct, or delete your personal information that we hold. If you wish to exercise these rights, please contact us at [Your Contact Information].</p>

                <h2>Changes to This Privacy Policy</h2>
                <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>

                <h2>Contact Us</h2>
                <p>If you have any questions about this Privacy Policy, please contact us at hynoteai@gmail.com.</p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
