// UseCasePage.js
import React from 'react';
import { Link } from 'react-router-dom';
import './UseCasePage.css'; // Make sure this points to your CSS file with correct styles
import { useTranslation } from 'react-i18next';
// Import the images (make sure the paths are correct)
import stock1 from './stock4.webp';
import stock2 from './stock5.webp';
import stock3 from './stock6.webp';

const HomePage = () => {
    const { t } = useTranslation();

  return (
    <div className="page-container">
      <h1 className="page-header">Hynote</h1>
      <h2 className="page-subheader">Join the growing shift towards automation! Hynote is your go-to for notetaking, script writing</h2>
      <h3 className="page-h3">and so much more...</h3>
      <Link to="/pricing" className="start-link">Try Free Today!</Link>
      <div className="use-cases-container">
        <div className="use-case">
          <div className="use-case-card">
            
              <h3>Voice Transcription</h3>
            
          </div>
          <img src={stock1} alt="Content Creator" className="use-case-image"/>
        </div>
        <div className="use-case">
          <div className="use-case-card">
           
              <h3>Blogging and SEO</h3>
         
          </div>
          <img src={stock2} alt="Student" className="use-case-image"/>
        </div>
        <div className="use-case">
          <div className="use-case-card">
            
              <h3>Textbook Summarization</h3>
 
          </div>
          <img src={stock3} alt="Medical Professional" className="use-case-image"/>
        </div>
        {/* ... other links to your subpages wrapped in use-case classes */}
      </div>
      <div className="about-container">
        <h1 className="about-title">{t('about_hynote_title')}</h1>
        <div className="about-content">
          <p>{t('about_hynote_intro')}</p>
          <p>
            <strong>{t('features')}</strong>
            <ul>
              <li>{t('intuitive_transcription')}</li>
              <li>{t('automatic_formatting')}</li>
              <li>{t('cloud_sync')}</li>
              <li>NEW: Youtube Script Writing</li>
              <li>NEW: Auto Blog Writing</li>
            </ul>
          </p>
          
          <p>{t('hynote_innovation')}</p>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
