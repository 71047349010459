import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from './AuthContext'; // Import your AuthContext
import html2canvas from 'html2canvas';
import MarkdownDisplay from './MarkdownDisplay';
import './SavedNotes.css'; // Make sure the path is correct
import htmlDocx from 'html-docx-js/dist/html-docx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import { marked } from 'marked';
import { useTranslation } from 'react-i18next';

const SavedNotes = () => {
    const [notes, setNotes] = useState([]); // State to hold the notes
    const { user } = useContext(AuthContext); // Retrieve the current user
    const [currentPage, setCurrentPage] = useState(1);
    const notesPerPage = 10;
    const indexOfLastNote = currentPage * notesPerPage;
    const [showExportOptions, setShowExportOptions] = useState(null);

const indexOfFirstNote = indexOfLastNote - notesPerPage;
const currentNotes = notes.slice(indexOfFirstNote, indexOfLastNote);

const { t } = useTranslation();

    const pageNumbers = [];
for (let i = 1; i <= Math.ceil(notes.length / notesPerPage); i++) {
    pageNumbers.push(i);
}


const getHtmlContent = (markdownContent) => {
    return marked(markdownContent);
  };
  
  
    
  const exportToPdf = async (noteId) => {
    const note = notes.find(note => note._id === noteId);

    if (note) {
        const input = document.createElement('div');
        input.innerHTML = getHtmlContent(note.content);
        document.body.appendChild(input);

        // Use html2canvas to create an image and then generate a PDF
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            pdf.addImage(imgData, 'JPEG', 0, 0);
            pdf.save(`${note.title}.pdf`);
            document.body.removeChild(input);
        });
    } else {
        console.error('Note not found for exporting.');
    }
};

    
    
    
const exportToHtml = (noteId) => {
    const note = notes.find(note => note._id === noteId);
    if (note) {
        const htmlContent = getHtmlContent(note.content);
        const blob = new Blob([htmlContent], { type: "text/html;charset=utf-8" });
        saveAs(blob, `${note.title}.html`);
    } else {
        console.error('Note not found for exporting to HTML.');
    }
};

    const exportToDocx = async (noteId) => {
        const note = notes.find(note => note._id === noteId);
        if (note) {
            const htmlContent = getHtmlContent(note.content);
        
    fetch(`${process.env.REACT_APP_API_BASE_URL}/convertToDocx`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ htmlContent, title: note.title }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`Error exporting to DOCX: ${response.statusText}`);
      }
      return response.blob();
    })
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'document.docx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(error => {
      console.error(error.message);
    });
    }};
  

const renderPageNumbers = pageNumbers.map(number => (
    <li key={number} className="page-number" onClick={() => setCurrentPage(number)}>
        {number}
    </li>
));

    // Function to handle note deletion
    const deleteNote = async (noteId) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/deletenote/${noteId}`, {
            method: 'DELETE',
          });
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          if(response.ok){
            refreshNotes();
          }
          console.log('Note deleted successfully');
        } catch (error) {
          console.error('Error deleting note:', error);
        }
      };
      
      const refreshNotes = async () => {
        if (!user || !user.id) {
            console.error('User ID is not available for fetching notes.');
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/notes?userId=${user.id}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setNotes(data.notes); // Assuming the response body shape is { notes: [] }
        } catch (error) {
            console.error('Error fetching notes:', error);
        }
    };

        // Toggle export options visibility
        const toggleExportOptions = (noteId) => {
            setShowExportOptions(prevNoteId => prevNoteId === noteId ? null : noteId);
        };
    
        // Close export options when mouse leaves
        const closeExportOptions = () => {
            setShowExportOptions(null);
        };
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check if the click is outside the export menu
            if (showExportOptions && !event.target.closest('.export-note-button') && !event.target.closest('.export-options')) {
                setShowExportOptions(null);
            }
        };
    
        // Add event listener
        document.addEventListener('mousedown', handleClickOutside);
    
        // Clean up the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showExportOptions]);
    

    useEffect(() => {
        // Function to retrieve notes from the server
        const fetchNotes = async () => {
            if (!user || !user.id) {
                console.error('User ID is not available for fetching notes.');
                return;
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/notes?userId=${user.id}`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setNotes(data.notes); // Assuming the response body shape is { notes: [] }
            } catch (error) {
                console.error('Error fetching notes:', error);
            }
        };

        fetchNotes(); // Retrieve notes when the component is mounted
    }, [user]);

    // Render the notes

    return (
        <div className="saved-notes-container">
            <h1>{t('saved_notes')}</h1>
            <ul>
                {currentNotes.map(note => (
                    <li 
                        key={note._id} 
                        className="note-item"
                        onMouseLeave={() => setShowExportOptions(null)}
                    >
                        <h2>{note.title}</h2>
                        <button className="delete-note-button" onClick={() => deleteNote(note._id)}>
                            <i className="fas fa-trash"></i> {/* Trash icon */}
                        </button>
                        <button 
                            className="export-note-button" 
                            onClick={() => setShowExportOptions(note._id)}
                            
                        >
                            <i className="fas fa-arrow-up"></i> {/* Up arrow icon */}
                        </button>
                        {showExportOptions === note._id && (
                            <div className="export-options" onMouseLeave={() => setShowExportOptions(null)}>
                                <button onClick={() => exportToPdf(note._id)}>PDF</button>
                                <button onClick={() => exportToDocx(note._id)}>DOCX</button>
                                <button onClick={() => exportToHtml(note.content)}>HTML</button>
                            </div>
                        )}
                        <div className="note-content">
                            <MarkdownDisplay content={note.content} />
                        </div>
                    </li>
                ))}
            </ul>
            <ul className="page-numbers">
                {renderPageNumbers}
            </ul>
        </div>
    );
    
    
};

export default SavedNotes;
