// App.js
import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import VoiceTranscription from './components/VoiceTranscription';
import { AuthProvider, AuthContext } from './components/AuthContext';
import HomePage from './components/HomePage';
import Header from './components/Header';  // make sure to import the Header component
import Signup from './components/Signup';
import Settings from './components/Settings';
import ModelsPage from './components/ModelsPage';
import SavedNotes from './components/SavedNotes';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Subscription from './components/Subscription';
import LanguageProvider from './components/LanguageProvider.js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ModernVoiceTranscription from './components/ModernVoiceTranscription';
import EmailConfirmation from './components/EmailConfirmation';
import ResetPassword from './components/ResetPassword';
import ForgotPassword from './components/ForgotPassword';
import { I18nextProvider } from 'react-i18next';
import i18n from './components/i18n'; // Import your i18n instance
import PrivacyPolicy from './components/PrivacyPolicy.js';
import CheckoutSuccess from './components/CheckoutSuccess.js';
import ConceptToWriteup from './components/ConceptToWriteup.js';
import ChoicePage from './components/ChociePage.js';
import Blog from './components/Blog.js';
import YoutubeScripter_auto from './components/YoutubeScripter_auto.js';
import ChoicePage2 from './components/ChoicePage2.js';
import YTVoiceTranscription from './components/YTVoiceTranscription.js';
import UseCasePage from './components/UseCasePage.js';
import SubPage1 from './components/SubPage1.js';
import SubPage2 from './components/SubPage2.js';
import Subpage3 from './components/SubPage3.js';


 // Path to your Subscription component

const stripePromise = loadStripe('your-public-key-here');


const App = () => {
  const { handleGoogleResponse } = useContext(AuthContext);

  useEffect(() => {
      // Dynamically load Google's One Tap script
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      document.body.appendChild(script);
    
      // Make handleCredentialResponse globally accessible
      window.handleCredentialResponse = (response) => {
          handleGoogleResponse(response);  // Use the method from your context
      };
    
      return () => {
        window.handleCredentialResponse = null;
      };
  }, [handleGoogleResponse]);


  return (
    <LanguageProvider>
    <div className="app-container">
    <AuthProvider>
       <GoogleOAuthProvider clientId="1096061175649-3gnhiqh8olncskp0koloktb4uucvsj8j.apps.googleusercontent.com">
       <Elements stripe={stripePromise}>
      <Router>
        <Header /> {/* This ensures the header is present on all pages */}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/vt" element={<VoiceTranscription />} />
          <Route path="/ct" element={<ConceptToWriteup />} />
          <Route path="/yta" element={<YoutubeScripter_auto />} />
          <Route path="/ytb" element={<YTVoiceTranscription />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/signup" element={<Signup />}/>
          <Route path="/models" element={<ModelsPage />}/>
          <Route path="/saved" element={<SavedNotes />}/>
          <Route path="/pricing" element={<Subscription />}/>
          <Route path="/settings" element ={<Settings />}/>
          <Route path="/confirm/:token" element={<EmailConfirmation />} />
          <Route path="/forgot-password" element ={<ForgotPassword />}/>
          <Route path="/reset-password/:resetToken" element={<ResetPassword />}/>
          <Route path="/privacy" element={<PrivacyPolicy/>}/>
          <Route path="/checkout-success" element={<CheckoutSuccess />}/>
          <Route path="/choice" element={<ChoicePage />} />
          <Route path="/choice2" element={<ChoicePage2/>} />
          <Route path="/blog" element={<Blog />} />
          <Route exact path="/use-cases"  element={<UseCasePage />} />
        <Route path="/use-cases/subpage1"  element={<SubPage1 />} />
        <Route path="/use-cases/subpage2"  element={<SubPage2 />} />
        <Route path="/use-cases/subpage3"  element={<Subpage3 />} />
        </Routes>
      </Router>
      </Elements>
      </GoogleOAuthProvider>
    </AuthProvider>
    </div>
    </LanguageProvider>
    
  );
};

export default App;
