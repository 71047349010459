import React, { createContext, useState, useEffect, useCallback } from 'react';

export const AuthContext = createContext({
    user: null, // default value
    setUser: () => {}, // default no-op, important as a fallback
    login: () => {},
    logout: () => {},
    handleGoogleResponse: () => {}, // Method to handle Google Sign-In response
    loading: true, 
});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    
    // Method to handle login
    const login = useCallback((userData) => {
        // Ensure the user object contains the ID
        const userWithId = {
            ...userData,
            id: userData.id,
            stripeCustomerId: userData.stripeCustomerId
        };
        
        setUser(userWithId);
        localStorage.setItem('user', JSON.stringify(userWithId)); // Store the user data with ID
    }, []);

    // Method to handle logout
    const logout = useCallback(() => {
        setUser(null);
        localStorage.removeItem('user');
        // Redirect to the homepage after logout
        window.location.href = '/';
    }, []);

    const handleGoogleResponse = useCallback((response) => {
        const idToken = response.tokenId;
        
        if (idToken) {
            fetch('http://localhost:3001/authenticate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ token: idToken })
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (data.user && data.user.id) { // Checking if the ID exists in the response
                    login(data.user);
                } else {
                    console.error('ID not present in the user object:', data);
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
        } else {
            console.error('No token received:', response);
        }
    }, [login]);

    useEffect(() => {
        // Load the user data from local storage (if available)
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
        setLoading(false); // Set loading to false after user data retrieval
    }, []);

    // Context value to be passed to the provider
    const contextValue = {
        user, 
        setUser,
        login, 
        logout,
        handleGoogleResponse, // Handle Google response
        loading,
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};
