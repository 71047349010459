import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ChoicePage.css'; // Import the CSS file
import { useTranslation } from 'react-i18next';
import iconBrainstorm from './choiceIcon2.png'; // Update the path
import iconSummarize from './choiceIcon1.png'; // Update the path
import iconScriptWriter from './choiceIcon3.png'; // Update the path
import bgImage from './bgImage.png';

const ChoicePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleChoiceClick = (choice) => {
    navigate(choice.path);
  };


  return (
    <div className="choice-container">
      <div className="img-background">
        </div>
      <div className="choice" onClick={() => navigate('/vt')}>
  <div className="choice-title">{t('summarize_title')}</div>
  <img src={iconSummarize} alt="Summarize" className="choice-icon" />
  <div className="choice-box">{t('summarize_description')}</div>
</div>
<div className="choice" onClick={() => navigate('/ct')}>
  <div className="choice-title">{t('brainstorm_title')}</div>
  <img src={iconBrainstorm} alt="Brainstorm" className="choice-icon" />
  <div className="choice-box">{t('brainstorm_description')}</div>
</div>



<div className="choice" onClick={() => navigate('/choice2')}>
  <div className="choice-title">Youtube Script Writer</div>
  <img src={iconScriptWriter} alt="Script Writer" className="choice-icon" />
  <div className="choice-box">
    Choose to either take your spoken words and convert it to a well organized, and easily understandable script,
    or generate an automatic script using short keywords.
  </div>
</div>

    </div>
  );
};

export default ChoicePage;
