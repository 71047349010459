import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const { t }= useTranslation();
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/forgot-password`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                setMessage('');
            } else {
                setMessage(t('forgot_success_message'));
            }
        } catch (error) {
            console.error('Error sending password reset email', error);
            setMessage(t('forgot_error_message'));
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <input 
                    type="email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    placeholder={t('enter_your_email')} 
                    required 
                />
                <button type="submit">{t('send_reset_link')}</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default ForgotPassword;
