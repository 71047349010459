import React from 'react';
import './Subpage1.css';
import { Link } from 'react-router-dom';
const Subpage2 = () => {
  return (
    <div className="subpage-container">
      <h1 className="subpage-header">Maximize Your Study Efficiency with Hynote</h1>
      <section className="subpage-section">
        <h2 className="subpage-subheader">Students, Elevate Your Note-Taking!</h2>
        <p className="subpage-text">
          In the ever-evolving world of education, students are continuously seeking innovative methods to enhance their study habits and academic performance. Hynote emerges as a transformative tool, meticulously designed to cater to the unique demands of the scholarly realm. With its advanced voice transcription capabilities and intelligent summarization algorithms, Hynote empowers students to capture lecture content with unparalleled precision, transforming hours of audio into concise, manageable notes.
        </p>
      </section>
      
      <section className="subpage-section">
        <h2 className="subpage-subheader">Effortless Organization and Retrieval</h2>
        <p className="subpage-text">
          The struggle to organize notes and retrieve crucial information swiftly is a common plight faced by students. Hynote's robust organizational system effortlessly categorizes notes by subject, date, or any custom tags you set. This intuitive structure ensures that the information you need for study sessions, research projects, or exam preparation is readily accessible, thereby optimizing your academic workflow and bolstering your learning efficiency.
        </p>
      </section>

      <section className="subpage-section">
        <h2 className="subpage-subheader">Tailored Study Aids</h2>
        <p className="subpage-text">
          Hynote recognizes the diversity in learning styles and adapts to support visual, auditory, and kinesthetic learners. It offers a plethora of features, including the generation of visual aids like summary diagrams and flashcards, audio playback for auditory revisiting of lectures, and interactive tools that encourage active engagement for hands-on learners. This personalized approach to study aids fosters a deeper understanding of material and a more dynamic study experience.
        </p>
      </section>
      
      <section className="subpage-section">
        <h2 className="subpage-subheader">Collaborate and Succeed Together</h2>
        <p className="subpage-text">
          Collaboration is a cornerstone of academic success. Hynote's collaborative features enable students to share notes with peers, engage in group study sessions, and collectively annotate and refine their notes. By facilitating a shared learning environment, Hynote not only amplifies individual study efforts but also cultivates a supportive academic community.
        </p>
      </section>
      <div className="more-use-cases">
        <Link to="/use-cases" className="more-use-cases-link">Back to Use Cases</Link>
      </div>
    
    </div>
  );
};

export default Subpage2;
