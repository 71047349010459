import React, { useState, useRef, useEffect, useContext } from 'react';
import Draggable from 'react-draggable';
import './VoiceTranscription.css';  // Import your CSS
import ReactMarkdown from 'react-markdown';
import SwipeableViews from 'react-swipeable-views';
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw';
import Stackedit from 'stackedit-js';
import MarkdownDisplay from './MarkdownDisplay';
import StackEditComponent from './StackEditComponent';
import { marked } from 'marked';
import UserMenu from './UserMenu'; 
import './UserMenu.css';
import htmlDocx from 'html-docx-js/dist/html-docx';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Document, Packer, Paragraph } from "docx";
import { AuthContext } from './AuthContext';
import { GoogleLogin } from 'react-google-login';
import CustomModal from './CustomModal'; // Import your modal component
import io from 'socket.io-client';
import VoiceTranscriptionService from './VoiceTranscriptionService';
import { Button, Input, Select, Typography } from 'antd';
import { Spin, notification } from 'antd';
import { useSwipeable } from 'react-swipeable';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import or create arrow components if you don't already have them

const getHtmlContent = (markdownContent) => {
  return marked(markdownContent);
};


  
const exportToPdf = () => {
  const input = document.getElementById('processed-transcription');
  if (input) {
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'JPEG', 0, 0);
      pdf.save("download.pdf");
    });
  } else {
    console.error('Element with id "processed-transcription" not found.');
  }
};

  
  
  

const exportToHtml = (htmlContent) => {
  const blob = new Blob([htmlContent], { type: "text/html;charset=utf-8" });
  saveAs(blob, 'document.html');
};

const colors = ['#ff9999', '#7dff8c', '#7db0ff', '#ffff99', '#ff99ff', '#ffb266'];

const modes = ['View', 'Process', 'Edit', 'Export'];

const YoutubeScripter_auto = () => {
  const { t, i18n } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [transcriptionText, setTranscriptionText] = useState('');
  const [processedText, setProcessedText] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const { user } = useContext(AuthContext);
   
    const [transcription, setTranscription] = useState('');
    const [markers, setMarkers] = useState([]);
    const [wordCount, setWordCount] = useState(0);  // New state for word count
    const markerContainerRef = useRef(null);
    const [docTitle, setDocTitle] = useState(''); 
    const [isSwipingEnabled, setIsSwipingEnabled] = useState(true);
    const [dragEnded, setDragEnded] = useState(false);
    const [showUserMenu, setShowUserMenu] = useState(false);
    const [loginKey, setLoginKey] = useState(true);
    const [isLoading, setIsLoading] = useState(false);  // New state for loading status
    const [error, setError] = useState(null);  // New state for errors
    
    const [charLimit, setCharLimit] = useState(null);  // New state for char limit
    const [socket, setSocket] = useState(null);
    const [notes, setNotes] = useState([]);
    const [microphone, setMicrophone] = useState(null);
    const [recordingduration, setRecordingDuration] = useState(0);
    const [recordingTimeLimit, setRecordingTimeLimit] = useState(null);
    const totalRecordingLimitInSeconds = 5400;
    const [selectedLanguage, setSelectedLanguage] = useState('en');
    const [model, setModel] = useState('nova-2');
    const navigate = useNavigate();
    const [isDragging, setIsDragging] = useState(false);
    const [scriptStyle, setScriptStyle] = useState('Informative');
    const [scriptFormat, setScriptFormat] = useState('Short');
    const [scriptPurpose, setScriptPurpose] = useState('Inform');
    const [activeMarkerIndex, setActiveMarkerIndex] = useState(null);
const [activeMarkerPosition, setActiveMarkerPosition] = useState({ x: 0, y: 0 });

const [userCharCount, setUserCharCount] = useState(0); 

const [sessionCharCount, setSessionCharCount] = useState(0);

const [isReconnecting, setIsReconnecting] = useState(false);


    useEffect(() => {
      /*fetch('${process.env.REACT_APP_API_BASE_URL}/getClientId')
          .then(response => {
              if (!response.ok) {
                  throw new Error('Network response was not ok ' + response.statusText);
              }
              return response.json();
          })
          .then(data => {
              setClientId(data.clientId);
          })
          .catch(error => console.error('Error fetching client ID:', error));*/
        
   
        // Fetch the char limit from the server when the component mounts
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getCharLimit`)
            .then(response => response.json())
            .then(data => setCharLimit(data.charLimit))
            .catch(error => console.error('Error fetching char limit:', error));
    }, []);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const saveNote = async () => {
      console.log('Current user object:', user); // Log the entire user object
    
      if (!user || !user.id) {
        console.error('User ID is not available');
        return;
      }
    
      try {
        const today = new Date();
        const formattedDate = today.toLocaleDateString();
        const formattedTime = today.toLocaleTimeString();
        const title = `${formattedDate} ${formattedTime} notes`;
    
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/saveNote`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            userId: user.id,
            title: title,
            content: transcription,
          }),
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        console.log('Note saved successfully');
        setShowSuccessModal(true); // Show success modal on successful save
      } catch (error) {
        console.error('Error saving note:', error);
      }
    };
    
  const saveProcessedNote = async () => {
    console.log('Current user object:', user); // Log the entire user object

    // Use 'sub' as the unique user ID
    if (!user || !user.id) {
        console.error('User ID is not available');
        return;
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/saveNote`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                userId: user.id,  // Change 'user.id' to 'user.sub'
                title: docTitle,
                content: processedTranscription
            }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        console.log('Note saved successfully');
    } catch (error) {
        console.error('Error saving note:', error);
    }
};

const [googleAccessToken, setGoogleAccessToken] = useState('1096061175649-d3u4qb32jgdsflefnlcttu4gth8qbm06.apps.googleusercontent.com');

  const handleGoogleLoginSuccess = (response) => {
    console.log('Google Login Successful', response);
    const accessToken = response.accessToken;
    setGoogleAccessToken(accessToken);
    // Now you can use this token to interact with Google Drive API
  };

  const handleGoogleLoginFailure = (error) => {
    console.error('Google Login Failed:', error);
  };

  // Function to upload content to Google Drive
  const exportToGoogleDrive = async () => {
    if (!googleAccessToken) {
      console.error('Google access token is not available.');
      return;
    }

    // The content you want to upload
    const content = processedTranscription; // Replace with your actual content
    const blob = new Blob([content], { type: 'text/plain' });
    const metadata = {
      'name': 'My Document', // Name of the file
      'mimeType': 'text/plain', // MIME type
    };

    const form = new FormData();
    form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
    form.append('file', blob);

    try {
      const response = await fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart', {
        method: 'POST',
        headers: new Headers({ 'Authorization': 'Bearer ' + googleAccessToken }),
        body: form,
      });

      const data = await response.json();
      if (response.ok) {
        console.log('File uploaded to Google Drive', data);
      } else {
        console.error('Error uploading file to Google Drive', data);
      }
    } catch (error) {
      console.error('Exception uploading file to Google Drive', error);
    }
  };

    const getNotes = async () => {
      if (!user || !user.id) {
          console.error('User or user ID is undefined');
          return;
      }
    
      try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/getNotes/${user.id}`);
          const data = await response.json();
          setNotes(data);
      } catch (error) {
          console.error('Error fetching notes:', error);
      }
    };
  
    const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);

    const toggleUserMenu = () => {
      setIsUserMenuVisible(!isUserMenuVisible); // toggle visibility
    };
  
  


    const handleProcessTranscription = async () => {
        setIsLoading(true);
        setError(null);
      
        if (!user) {
          navigate('/login');
          setIsLoading(false);
          return;
        }
      
        try {
            const requestBody = {
              user: { id: user.id },
              transcription: transcription,
              style: scriptStyle, // Send the selected style
              format: scriptFormat // Send the selected format
            };
              // Choose the endpoint based on the selected format
              let endpoint;
              switch (scriptFormat) {
                case 'Long':
                  endpoint = '/processTranscription_yt_long';
                  break;
                case 'Medium':
                  endpoint = '/processTranscription_yt_med';
                  break;
                default:
                  endpoint = '/processTranscription_yt_short';
              }
      
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}${endpoint}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody),
          });
          
          if (response.status === 403) {
            // Creating an element with a link embedded in the message
            const errorMessageWithLink = (
              <span>
                {t('inactive_sub1')}{' '}
                <a href="/pricing" style={{color: 'blue'}}>{t('inactive_sub2')}</a> {' '}
                {t('inactive_sub3')}
              </span>
            );
          
            setError(errorMessageWithLink); // setting the element as 'error'
            setModalOpen(true);
            return;
          }
            if (response.status === 429) {
            setError(<span>You have reached your character limit for the day. Please try again tomorrow.</span>);
          } else if (response.status === 399) {
            setError(<span>No text provided for transcription.</span>);
          } else if (response.status === 400) {
            setError(<span>Transcription text exceeds the maximum allowed length.</span>);
          } else if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          } else {
            const data = await response.json();
            setProcessedTranscription(data.processedText);
            setCurrentSlide(currentSlide + 1);
          }
        } catch (error) {
          setError(error.message);
        } finally {
          setIsLoading(false);
          setModalOpen(error != null);
        }
      };
      
    
  
  const fetchCharLimit = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/getCharLimit`);
        const data = await response.json();
        setCharLimit(data.charLimit);
    } catch (error) {
        console.error('Error fetching char limit:', error);
    }
};

const handleTextChange = async (event) => {
    const text = event.target.value;
    setTranscription(text);
    setWordCount(text.split(/\s+/).filter(Boolean).length);  // Update word count
    setSessionCharCount(text.length);
    // Ensure charLimit has been retrieved before proceeding
    if (charLimit === null) {
        await fetchCharLimit();
    }
    
    // Now that charLimit has been retrieved, proceed with chunking
    if (charLimit !== null) {
        const newMarkers = autoChunkText(text, charLimit);
        setMarkers(newMarkers);
    }
};


  const autoChunkText = (text, limit) => {
    const newMarkers = [];
    let position = limit;
    while (position < text.length) {
      newMarkers.push(position);
      position += limit;
    }
    return newMarkers;
  };

  const [processedTranscription, setProcessedTranscription] = useState('');  // New state for processed transcription

    // Function to open the StackEdit editor
    const openEditor = () => {
        // Create the Stackedit object.
        const stackedit = new Stackedit();
        
        // Open the iframe
        stackedit.openFile({
          name: 'Filename',
          content: {
            text: processedTranscription, // and the Markdown content.
          }
        });
        
          // Listen to StackEdit events and apply the changes to the processedTranscription state.
    stackedit.on('fileChange', (file) => {
        setProcessedTranscription(file.content.text);
      });
    };

    
    const maxChunkLimit = 3900;


    const exportToDocx = () => {
        const markdownContent = processedTranscription;  // Assuming processedTranscription holds your markdown content
        const htmlContent = getHtmlContent(markdownContent);
        fetch(`${process.env.REACT_APP_API_BASE_URL}/convertToDocx`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ htmlContent, title: docTitle }),
        })
        .then(response => {
          if (!response.ok) {
            throw new Error(`Error exporting to DOCX: ${response.statusText}`);
          }
          return response.blob();
        })
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = 'document.docx';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(error => {
          console.error(error.message);
        });
      };
      const [savedNotesOpen, setSavedNotesOpen] = useState(false);

      const handleOpenSavedNotes = () => {
        setSavedNotesOpen(true);
        // Here, you would also trigger any data fetching for the saved notes
      };
    
      

  const handleContentChange = (newContent) => {
    setProcessedTranscription(newContent);
  };

  const handleResetConfirmation = () => {
    const confirmReset = window.confirm("This will reset your markers to the default position, would you like to continue?");
    if (confirmReset) {
      resetMarkers();
    }
  };

  const handleChangeIndex = (index, indexLatest) => {
    if (index === indexLatest) {
      // Swipe was completed successfully
      setIsDragging(false);
      setIsSwipingEnabled(true);
    } else {
      // Swipe was interrupted or incomplete
      setTimeout(() => {
        setIsSwipingEnabled(true);
      }, 100); // Adjust delay if needed
    }
    setCurrentSlide(index);
    window.scrollTo(0, 0);
  };


  useEffect(() => {
    console.log('Markers:', markers);
    console.log('markerContainerRef.current:', markerContainerRef.current);
  }, [markers]);

  
  
  useEffect(() => {
    const handleResize = () => {
      setMarkers((prevMarkers) => [...prevMarkers]);
    };
  
    // Trigger the handleResize function whenever the currentSlide changes
    handleResize();
  
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [currentSlide]);   // Add mode as a dependency here
  

  const [isRecording, setIsRecording] = useState(false);
  const [showRecordingRing, setShowRecordingRing] = useState(false);

  const [remainingTime, setRemainingTime] = useState(null); // Set initial to null
const [timer, setTimer] = useState(null);

  useEffect(() => {
    // Setup listeners on mount
    VoiceTranscriptionService.setupListeners({
      onTranscript: (transcript) => {
        setTranscription(prevTranscription => prevTranscription + ' ' + transcript);
      },
    });

    // Remove listeners on unmount
    return () => {
      VoiceTranscriptionService.removeListeners();
    };
}, []);

useEffect(() => {
  const handleGlobalMouseUp = () => {
    if (!isDragging) {
      setDragEnded(true);
      setTimeout(() => {
        manageSwipingState(true);
        setDragEnded(false);
      }, 100);
    }
  };

  document.addEventListener('mouseup', handleGlobalMouseUp);

  return () => {
    document.removeEventListener('mouseup', handleGlobalMouseUp);
  };
}, [isDragging]);


const [allowSwipe, setAllowSwipe] = useState(true);
const manageSwipingState = (enableSwiping) => {
  if (enableSwiping && !isDragging) {
    setIsSwipingEnabled(true);
  } else {
    setIsSwipingEnabled(false);
  }
};

const handleDragStart = () => {
  setIsDragging(true);
  manageSwipingState(false);
};

const handleClickAnywhere = () => {
  if (!isDragging) {
    setIsSwipingEnabled(true);
  }
};
const handleDragStop = () => {
  setIsDragging(false);
  setTimeout(controlSwipeTransition, 100);  // Small delay to ensure transition
};


const getColorBasedOnCharCount = (charCount) => {
  const ratio = charCount / maxChunkLimit;
  const hue = Math.floor((1 - ratio) * 120); // 0 (red) to 120 (green)
  return `hsl(${hue}, 100%, 40%)`; // Adjust saturation and lightness as needed
};


const handleMouseDown = () => {
  setDragEnded(false);  // Ensure dragEnded state is reset
  controlSwipeTransition();
};

const handleMouseUp = () => {
  setDragEnded(false);  // Reset dragEnded state
  controlSwipeTransition();
};

const resetMarkers = () => {
  const newMarkers = autoChunkText(transcription, charLimit);
  setMarkers(newMarkers);
};

const handleDrag = (event, index, data) => {
  event.preventDefault();
  event.stopPropagation();

  const rect = markerContainerRef.current.getBoundingClientRect();
  let newPosition = Math.round(data.x / rect.width * transcription.length);

  // Constrain newPosition within bounds of transcription length
  newPosition = Math.max(0, Math.min(newPosition, transcription.length));

  let newMarkers = [...markers];
  newMarkers[index] = newPosition;

  // Adjust the first marker to respect maxChunkLimit from the start
  if (index === 0) {
    newMarkers[0] = Math.min(newMarkers[0], maxChunkLimit);
  }

  // Adjust preceding markers
  for (let i = index - 1; i >= 0; i--) {
    newMarkers[i] = Math.min(newMarkers[i], newMarkers[i + 1] - 1); // Prevent crossing
    if (i > 0) {
      newMarkers[i] = Math.max(newMarkers[i], newMarkers[i - 1] + 1, newMarkers[i + 1] - maxChunkLimit);
    }
  }

  // Adjust following markers
  for (let i = index + 1; i < newMarkers.length; i++) {
    newMarkers[i] = Math.max(newMarkers[i], newMarkers[i - 1] + 1); // Prevent crossing
    if (i < newMarkers.length - 1) {
      newMarkers[i] = Math.min(newMarkers[i], newMarkers[i + 1] - 1, newMarkers[i - 1] + maxChunkLimit);
    }
  }

  // Respect maxChunkLimit for the current marker
  if (index > 0) {
    newMarkers[index] = Math.max(newMarkers[index], newMarkers[index - 1] + 1);
    newMarkers[index] = Math.min(newMarkers[index], newMarkers[index - 1] + maxChunkLimit);
  }
  if (index < newMarkers.length - 1) {
    newMarkers[index] = Math.min(newMarkers[index], newMarkers[index + 1] - 1);
    newMarkers[index] = Math.max(newMarkers[index], newMarkers[index + 1] - maxChunkLimit);
  }

  setMarkers(newMarkers);
  setActiveMarkerIndex(index);
  setActiveMarkerPosition({
    x: data.x + markerContainerRef.current.offsetLeft,
    y: markerContainerRef.current.offsetTop + markerContainerRef.current.offsetHeight
  });
};


// Helper function to enforce constraints and limits on markers
const updateMarkersWithConstraints = (newMarkers, index) => {
  // Constraints to prevent chunks from shrinking too small or going beyond maxChunkLimit
  // Only check and update adjacent markers to improve performance
  if (index > 0) {
    newMarkers[index] = Math.max(newMarkers[index], newMarkers[index - 1]);
  }
  if (index < newMarkers.length - 1) {
    newMarkers[index] = Math.min(newMarkers[index], newMarkers[index + 1]);
  }

  // Enforce the maxChunkLimit for the affected chunk(s)
  if (index > 0 && newMarkers[index] - newMarkers[index - 1] > maxChunkLimit) {
    newMarkers[index] = newMarkers[index - 1] + maxChunkLimit;
  }
  if (index < newMarkers.length - 1 && newMarkers[index + 1] - newMarkers[index] > maxChunkLimit) {
    newMarkers[index + 1] = newMarkers[index] + maxChunkLimit;
  }
};

const [isMouseOverMarkerContainer, setIsMouseOverMarkerContainer] = useState(false);

// Part of your React component
const updateUserCharCount = async () => {
  if (!user || !user.id) {
    console.warn("User ID is not available for fetching character count.");
    return;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserCharCount?userId=${user.id}`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    setUserCharCount(data.charCount); // Assuming the backend sends the count in a field named 'charCount'
  } catch (error) {
    console.error('Error fetching user character count:', error);
  }
};
const stopTimer = () => {
  clearInterval(timer); // Stop the countdown
  setTimer(null); // Reset the timer state
};



//Character count
useEffect(() => {
  if (user) {
    updateUserCharCount();
  }
}, [user]);

//get chunks

  const getChunks = () => {
    const chunks = [];
    let lastPosition = 0;
    markers.forEach((marker, index) => {
      chunks.push({
        text: transcription.slice(lastPosition, marker),
        color: colors[index % colors.length],
        start: lastPosition,
        end: marker
      });
      lastPosition = marker;
    });
    chunks.push({
      text: transcription.slice(lastPosition),
      color: colors[markers.length % colors.length],
      start: lastPosition,
      end: transcription.length
    });
    console.log(chunks);  // Log the chunks to the console
    return chunks;
  };


  const darkenColor = (color, amount = 20) => {
    let usePound = false;
  
    if (color[0] === "#") {
      color = color.slice(1);
      usePound = true;
    }
  
    const num = parseInt(color, 16);
    let r = (num >> 16) - amount;
    let b = ((num >> 8) & 0x00FF) - amount;
    let g = (num & 0x0000FF) - amount;
  
    r = Math.max(Math.min(255, r), 0);
    g = Math.max(Math.min(255, g), 0);
    b = Math.max(Math.min(255, b), 0);
  
    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  };
  
  function updateMarkerAfterColors() {
    const markers = document.querySelectorAll('.marker');
    markers.forEach(marker => {
        const color = window.getComputedStyle(marker).backgroundColor;
        marker.style.setProperty('--marker-before-color', color);
    });
}

// Call the function to update colors
updateMarkerAfterColors();
const handleSwipeTransitionEnd = () => {
  // Reset states when the swipe transition ends or is cancelled
  setIsDragging(false);
  setIsSwipingEnabled(true);
};

useEffect(() => {
  const handleGlobalTouchEnd = () => {
    // Only reset states if a swipe or drag was in progress
    if (isDragging || !isSwipingEnabled) {
      setIsDragging(false);
      setIsSwipingEnabled(true);
    }
  };

  // Add the global event listener
  window.addEventListener('touchend', handleGlobalTouchEnd);

  // Cleanup the event listener
  return () => {
    window.removeEventListener('touchend', handleGlobalTouchEnd);
  };
}, [isDragging, isSwipingEnabled]);

useEffect(() => {
  fetchRecordingTimeLimit();
}, []);


useEffect(() => {
  // Fetch user's used recording time when component mounts or user changes
  

  const fetchUsedRecordingTime = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserRecordingTime?userId=${user.id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch recording time');
      }
      const data = await response.json();
  
      console.log('Fetched recording time data:', data); // Add this to check the response
  
      // Validate if data.recordingTime is a number
      if (typeof data.recordingTime !== 'number') {
        console.error('Invalid recording time received:', data.recordingTime);
        setRemainingTime(0);
        return;
      }
  
      setUsedRecordingTime(data.recordingTime);
      
      const remainingTime = totalRecordingLimitInSeconds - data.recordingTime;
      setRemainingTime(Math.max(0, remainingTime));
      startCountdownTimer(remainingTime);
    } catch (error) {
      console.error('Error fetching recording time:', error);
      setRemainingTime(0); // Set to 0 if an error occurs
    }
  };
  

  if (user) {
    fetchUsedRecordingTime();
  }
}, [user]);


const handleScroll = () => {
  const markerContainer = document.querySelector('.marker-container');

  const scrollPosition = window.scrollY || document.documentElement.scrollTop;



  
  if (markerContainer) {
    markerContainer.style.top = `${scrollPosition}px`;
  }

};



  
  // Add event listener when the component mounts
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  

  const controlSwipeTransition = () => {
    if (!isDragging) {
      setIsSwipingEnabled(true);
    } else {
      setIsSwipingEnabled(false);
    }
  };
  
  let recordingInterval;
  const [usedRecordingTime, setUsedRecordingTime] = useState(0); // Initialize state
  const handleMicrophone = async () => {
    if (!user) {
      // Redirect to login if not logged in, or show a message if not subscribed
      if (!user) {
        navigate('/login'); // or use a different method to navigate
      } else {
        alert(t('subscribe_message'));
      }  } else {
    console.log('Microphone button clicked.');
  
    if (isRecording) {
      setIsRecording(false);
      setIsReconnecting(true); // Start reconnecting process
      clearInterval(recordingInterval);
      setShowRecordingRing(false);
      if (microphone) {
          microphone.stop();
          setMicrophone(null);
      }

      // Reset the VoiceTranscriptionService
      VoiceTranscriptionService.resetConnection();

      // Simulate a delay for reconnection (adjust time as needed)
      setTimeout(() => {
          setIsReconnecting(false); // End reconnecting process
      }, 3000); // 3 seconds delay for reconnection

      return;
  }

  
  if (!isRecording && !isReconnecting) {
    // Reinitialize the VoiceTranscriptionService
    VoiceTranscriptionService.initializeConnection();

      if (socket) {
        socket.emit('checkDeepgramStatus');
      }
     
  
    // Check if the recording limit has been reached
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserRecordingTime?userId=${user.id}`);
      const data = await response.json();
      if (!response.ok) {
        if (response.status === 403) {
       
          if (microphone) {
            microphone.stop();  // Directly stop the microphone
          }
          // Remaining cleanup code
          clearInterval(recordingInterval);
          setIsRecording(false); // Update state, though it might be delayed
          setShowRecordingRing(false);
          alert(t('susbcribe_message'));
          return;
        } 
      }
  
      // Proceed to start recording
      const userMedia = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mic = new MediaRecorder(userMedia);
      setMicrophone(mic);
  
      mic.ondataavailable = (e) => {
        VoiceTranscriptionService.sendAudioData(e.data);
      };
  
      mic.onstop = () => {
        clearInterval(recordingInterval);
        setIsRecording(false);
        setShowRecordingRing(false);
        stopTimer();
        // Do not set microphone to null here, as it's already handled above
      };
  
      setIsRecording(true);
      setShowRecordingRing(true);
      mic.start(700); // Adjust timeslice as needed
      startTimer();
    } catch (error) {
      console.error('Error:', error);
      alert(t('mic_error'));
    }
    VoiceTranscriptionService.emit('startRecording', {
      language: selectedLanguage,
      model: 'nova-2',
    });
  }
}
};
  

const startTimer = () => {
  const maxRecordingTimeInSeconds = recordingTimeLimit / 1000;
  let localRecordingTime = 0; // Local counter for recording time

  recordingInterval = setInterval(async () => {
      localRecordingTime += 1000; // Increment local recording time by 1 second

      // Update the remaining time in the UI
      const remainingRecordingTime = maxRecordingTimeInSeconds - localRecordingTime;
      setRemainingTime(Math.max(0, remainingRecordingTime));

      // Check if local recording time has reached or exceeded the limit
      if (localRecordingTime >= maxRecordingTimeInSeconds) {
          console.log('Recording stopped due to time limit reached');
          clearInterval(recordingInterval);
          if (microphone) {
              microphone.stop();
              setIsRecording(false);
              setShowRecordingRing(false);
          }
          alert(t('mic_time_limit'));
          return;
      }

      // Regular update to server, assuming 1 second has passed
      updateRecordingTimeOnServer(1000);

      // Periodic check with the server to ensure synchronization
      if (localRecordingTime % 60 === 0) { // Every 60 seconds
          try {
              const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserRecordingTime?userId=${user.id}`);
              const data = await response.json();
              if (!response.ok) {
                  throw new Error('Failed to fetch recording time');
              }

              // Adjust localRecordingTime based on server response
              localRecordingTime = data.recordingTime;

              // Additional check to handle any discrepancies
              if (localRecordingTime >= maxRecordingTimeInSeconds) {
                  console.log('Recording stopped due to server time limit reached');
                  clearInterval(recordingInterval);
                  if (microphone) {
                      microphone.stop();
                      setIsRecording(false);
                      setShowRecordingRing(false);
                  }
                  setRemainingTime(0);
                  alert(t('mic_time_limit'));
                  return;
              }
          } catch (error) {
              console.error('Error while fetching recording time:', error);
          }
      }
  }, 1000); // Check every second
};



const fetchUsedRecordingTime = async () => {
  try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserRecordingTime?userId=${user.id}`);
      const data = await response.json();
      if (!response.ok) {
          throw new Error('Failed to fetch recording time');
      }
      setUsedRecordingTime(data.recordingTime);
  } catch (error) {
      console.error('Error fetching recording time:', error);
  }
};




  
const startCountdownTimer = (initialTime) => {
  clearInterval(timer);
  const newTimer = setInterval(() => {
    setRemainingTime((prevTime) => {
      if (prevTime <= 1) {
        clearInterval(newTimer);
        if (microphone) microphone.stop();
        return 0;
      }
      return prevTime - 1;
    });
  }, 1000);
  setTimer(newTimer);
};



// Client-side: React Component
const updateRecordingTimeOnServer = async (duration) => {
  if (!user || !user.id) {
    console.error("User ID is not available for updating recording time.");
    return;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/updateUserRecordingTime`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userId: user.id, recordingTime: duration })
    });

    if (!response.ok) {
      if (response.status === 403) {
        console.log("Recording time limit exceeded. Stopping recording directly.");
        if (microphone) {
          microphone.stop();  // Directly stop the microphone
        }
        // Remaining cleanup code
        clearInterval(recordingInterval);
        setIsRecording(false); // Update state, though it might be delayed
        setShowRecordingRing(false);
        alert(t('susbcribe_message'));
      } 
      if (response.status === 400) {
        console.log("Recording time limit exceeded. Stopping recording directly.");
        if (microphone) {
          microphone.stop();  // Directly stop the microphone
        }
        // Remaining cleanup code
        clearInterval(recordingInterval);
        setIsRecording(false); // Update state, though it might be delayed
        setShowRecordingRing(false);
        alert(t('mic_time_limit'));
      } 
      console.log("Recording time updated successfully.");
    }
  } catch (error) {
    console.error('Error updating recording time:', error);
    stopRecording(); // Function to stop recording
  }
};

const stopRecording = () => {
  if (microphone) {
    microphone.stop();
    setMicrophone(null);
    setIsRecording(false);
    setShowRecordingRing(false);
    
  }
  stopTimer(); // Ensure the timer is stopped
};

  useEffect(() => {
    if (!isRecording && microphone) {
      // If isRecording is false and microphone exists, stop it
      microphone.stop();
    }
  }, [isRecording, microphone]);

const formatTime = (seconds) => {
  if (seconds === null) {
    return "Loading..."; // Display while loading
  }
  if (seconds === 0) {
    return "No time remaining"; // Display when no time is left
  }
  const mins = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${mins}m ${secs}s`;
};


    // Google Drive authentication logic
  // Google Drive authentication logic
  const googleDriveLogin = useGoogleLogin({
    clientId: '1096061175649-d3u4qb32jgdsflefnlcttu4gth8qbm06.apps.googleusercontent.com',
    onSuccess: (codeResponse) => {
        console.log("Code response:", codeResponse);
        const authCode = codeResponse.code;

        if (!authCode) {
            console.error('No authorization code found in the response.');
            return;
        }

        // Send the authorization code to your server
        fetch(`${process.env.REACT_APP_API_BASE_URL}/driveAuthenticate`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ code: authCode }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Server response:', data);
            // Handle the server's response, like storing the access token
        })
        .catch(error => console.error('Error sending auth code to server:', error));
    },
    onError: (error) => {
        console.error('Google Drive Login Failed:', error);
    },
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/drive.file' // Specify the Google Drive scope
});


const handleUploadToGoogleDrive = () => {
  googleDriveLogin();
};

const fetchRemainingTime = async () => {
  try {
    const userId = user.id; // Ensure this is correctly obtained
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/getRemainingTime?userId=${userId}`);
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    console.log('Fetching remaining time for userId:', userId);
    const data = await response.json();
    console.log('Remaining time fetched:', data.remainingTime);
    if (typeof data.remainingTime === 'number') {
      setRemainingTime(data.remainingTime);
      // Only start timer if there is time remaining
      if (data.remainingTime > 0) {
          startCountdownTimer(data.remainingTime);
      }
    } else {
      console.error('Invalid remaining time received:', data.remainingTime);
    }
  } catch (error) {
    console.error('Error fetching remaining time:', error);
  }
};
  
  const fetchRecordingTimeLimit = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/getRecordingTimeLimit`);
        if (!response.ok) {
          if (response.status === 403) {
            console.log("Recording time limit exceeded. Stopping recording directly.");
            if (microphone) {
              microphone.stop();  // Directly stop the microphone
            }
            // Remaining cleanup code
            clearInterval(recordingInterval);
            setIsRecording(false); // Update state, though it might be delayed
            setShowRecordingRing(false);
            alert(t('susbcribe_message'));
          } 
            throw new Error(`HTTP error! status: ${response.status}`);

            
        }
        const data = await response.json();
        console.log('Fetched recording time limit:', data.recordingTimeLimit);
        setRecordingTimeLimit(data.recordingTimeLimit); // Ensure this is the correct field from your response
    } catch (error) {
        console.error('Error fetching recording time limit:', error);
    }
};

useEffect(() => {
    fetchRecordingTimeLimit();
}, []);

useEffect(() => {
  // This will run when the component mounts and whenever the user changes
  if (user) {
    fetchUserRecordingData();
  }
}, [user]);

const fetchUserRecordingData = async () => {
  if (!user || !user.id) {
    console.log('User data not available for fetching recording time data.');
    return;
  }
  try {
    const timeLimitResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/getRecordingTimeLimit`);
    const timeLimitData = await timeLimitResponse.json();
    setRecordingTimeLimit(timeLimitData.recordingTimeLimit);

    const usedTimeResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/getUserRecordingTime?userId=${user.id}`);
    const usedTimeData = await usedTimeResponse.json();
    setUsedRecordingTime(usedTimeData.recordingTime);

    if (typeof usedTimeData.recordingTime !== 'number') {
      console.error('Invalid recording time received:', usedTimeData.recordingTime);
      setRemainingTime(0);
      return;
    }

    const remainingRecordingTime = (timeLimitData.recordingTimeLimit / 1000) - usedTimeData.recordingTime;
    setRemainingTime(remainingRecordingTime);
    startCountdownTimer(remainingRecordingTime);
  } catch (error) {
    console.error('Error fetching recording time data:', error);
  }
};
  
  const updateTimer = (initialTime) => {
    clearInterval(timer); // Clear any existing timer
    const newTimer = setInterval(() => {
      setRemainingTime(prevTime => {
        if (prevTime <= 0) {
          clearInterval(newTimer);
          if (microphone) microphone.stop(); // Stop recording if time runs out
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
    setTimer(newTimer);
  };
  
  useEffect(() => {
    console.log('Initial remainingTime:', remainingTime);
    console.log('Initial usedRecordingTime:', usedRecordingTime);
    fetchRemainingTime();
    fetchUserRecordingData();
  }, [user]);



  function changeMarkerColors(newColor) {
    const markers = document.querySelectorAll('.marker');

    markers.forEach(marker => {
        // Apply the new color to the marker
        marker.style.backgroundColor = newColor;

        // Update the ::before pseudo-element using a custom property
        marker.style.setProperty('--marker-before-color', newColor);
    });
}
const handleLanguageChange = async (event) => {
  const newLanguage = event.target.value;
  let newModel = 'nova-2'; // Default model

  // Assign model based on language
  if (['en', 'hi', 'es', 'es-419', 'hi-Latn', 'fr'].includes(newLanguage)) {
    newModel = 'nova-2';
  }

  setSelectedLanguage(newLanguage);
  setModel(newModel);

  // Emit a socket event for changing the language
  VoiceTranscriptionService.emitLanguageChange(newLanguage, newModel);
};


  
  return (
    <div className="YTvoiceTranscriptionContainer">
        <button className="arrowButton" onClick={() => setCurrentSlide(prev => Math.max(0, prev - 1) ) }>
    &lt;
  </button>
  <SwipeableViews
  enableMouseEvents
  disabled={!isSwipingEnabled || isDragging}
  index={currentSlide}
  onChangeIndex={handleChangeIndex}
  onTransitionEnd={handleSwipeTransitionEnd}
>
        {/* View Mode */}
        <div className="slide">
        <React.Fragment>
                <div>
                    
                </div>
                
                <br />
                <h2>{t('brainstorm')}</h2>
                <p className="word-count">{t('writetopic2')}</p>                <p /><br></br>
                <textarea
                    value={transcription}
                    onChange={handleTextChange}
                    rows="2" cols="20"
                /> <div className="form-wrapper">
                <label>Style:</label>
                <select value={scriptStyle} onChange={(e) => setScriptStyle(e.target.value)}>
                  <option value="Informative">Informative</option>
                  <option value="Persuasive">Persuasive</option>
                  <option value="Natural">Natural</option>
                  <option value="Excited">Excited</option>
                </select>
                <label>Format:</label>
                <select value={scriptFormat} onChange={(e) => setScriptFormat(e.target.value)}>
                    <option value="Short">Short (1-60s)</option>
                    <option value="Medium">Medium (1m-5m)</option>
                    <option value="Long">Long Form (5m+)*</option>
                </select><br></br>
                
                <label>Purpose:</label>
                <select value={scriptPurpose} onChange={(e) => setScriptPurpose(e.target.value)}>
                    <option value="general">General</option>
                    <option value="video-essay">Video Essay</option>
                    <option value="reaction-video">Reaction Video</option>
                    <option value="factual">Factual</option>
                    <option value="tier">Tier List</option>
                    <option value="top10">Top 10</option>
                    <option value="awareness">Raise Awareness</option>
                    <option value="statement">Make a statement</option>
                    <option value="apology">Apology Video</option>
                    <option value="news">News(coming Jan 2024)</option>
                </select><br></br>
                * Long Form will quickly use up your daily limit. Not recommended for basic users as it will use ALL of the character limit.
              </div>
                {/* ...remaining View mode UI... */}
                <div className="centered-button-container">
                {isLoading ? (
                    <Spin size="large" />  // This uses Ant Design's Spin component for the loading indicator
                ) : (
                    <button className="summarize-button" onClick={handleProcessTranscription}>{t('summarize')}</button>
                )}
                </div>
            </React.Fragment>
        </div>
  
  
        {/* Edit Mode */}
        <div className="slide">
        <React.Fragment>
        <h2>{t('edit')}</h2>
        <p>{t('click_below_to_make_your_final_touches')}</p>
                
                
                <div id="processed-transcription" onClick={openEditor} className="processed-transcription">
                    <MarkdownDisplay  content={processedTranscription} />
                </div>
            </React.Fragment>
        </div>
  
        {/* Export Mode */}
        <div className="slide">
        <div className="presentation">
  <React.Fragment>
  <h2>{t('export_and_save')}</h2>
  <p>{t('choose_to_export_or_save')}</p>
    <input
      type="text"
      value={docTitle}
      onChange={(e) => setDocTitle(e.target.value)}
      placeholder={t('enter_document_title')}
    /><br />
    <button onClick={() => {
      const markdownContent = processedTranscription;
      const htmlContent = getHtmlContent(markdownContent);
      exportToDocx(htmlContent);  // Export to DOCX
    }}>{t('export_to_docx')}</button>
    <button onClick={() => {
      exportToPdf();  // Export to PDF
    }}>{t('export_to_pdf')}</button>
    <button onClick={() => {
      const markdownContent = processedTranscription;
      const htmlContent = getHtmlContent(markdownContent);
      exportToHtml(htmlContent);  // Export to HTML
    }}>{t('export_to_html')}</button>
   {/* <button onClick={handleUploadToGoogleDrive}>Upload to Google Drive</button> */}
    <button onClick={saveProcessedNote}>{t('save_to_cloud')}</button>
    {/* ...remaining Export mode UI... */}
  </React.Fragment>
</div>      </div>
      </SwipeableViews>
  
      {/* Navigation Dots and Buttons */}
      <div className="slideNavigation">
  <div className="navigationDots">
    {[0, 1, 2].map((slideNumber) => (
      <div
        key={slideNumber}
        className={`navigationDot ${slideNumber === currentSlide ? 'active' : ''}`}
      />
    ))}
  </div>
  {currentSlide === 0 && (
  <div>
 
                </div>
   )} 
  <button className="arrowButton" onClick={() => setCurrentSlide(prev => Math.min(3, prev + 1))}>
    &gt;
  </button>
</div>
               
    {currentSlide === 1 && (
<button className="edit-button slide-dependent-button" onClick={openEditor}><i class="far fa-edit"></i>
                </button>
                )}


      {/* Conditional rendering of the modal, if necessary */}
      {modalOpen && (
        <CustomModal
          message={error}
          onClose={() => setModalOpen(false)}
        />
      )}
  {showSuccessModal && (
    <CustomModal
      message="Note saved successfully!"
      onClose={() => setShowSuccessModal(false)}
    />
  )}
      

    </div>
  );

};

export default YoutubeScripter_auto;
