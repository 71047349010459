// CustomModal.js
import React from 'react';
import './CustomModal.css'; // Assuming you want to style your modal

const CustomModal = ({ message, onClose }) => {
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <h2>Notification</h2>
        <p>{message}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default CustomModal;
