import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import './Header.css';
import desktopLogo from './companyLogo.png'; // Adjust the path as necessary
import defaultProfilePic from './defaultProfilePic.png'; // Path to your default placeholder image
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import mobileLogo from './mobileLogo.png';  // Import the mobile logo
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';


const Header = () => {
  const { user, logout } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const userProfilePic = user?.imageUrl || defaultProfilePic; // using the image URL from the logged-in user data or a default one
  const { t } = useTranslation();

  // Function to get the initial from the username
// Function to get the initial from the user's name or username
const getUserInitial = () => {
  // Use 'name' or 'username' based on which property you have in the user object
  const name = user?.name || user?.username;
  return name ? name.charAt(0).toUpperCase() : "?";
};

const [avatarMenuOpen, setAvatarMenuOpen] = useState(false);
const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    if (mobileMenuOpen) {
      setAnchorEl(null); // Close the avatar menu when opening the hamburger menu
    }
  };
  
  // Toggle avatar menu and close mobile menu if open
  const handleClick = (event) => {
    if (window.innerWidth <= 900) {
      setAvatarMenuOpen(!avatarMenuOpen);
      setMobileMenuOpen(false);
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  
  const handleClose = () => {
    setAnchorEl(null);
    setAvatarMenuOpen(false);
    setMobileMenuOpen(false);
  };
  
  useEffect(() => {
    const handleResize = () => {
        if (window.innerWidth > 900) {
            setMobileMenuOpen(false);
            setAvatarMenuOpen(false);
        }
    };

    const handleOutsideClick = (event) => {
        // Close the mobile menu if clicking outside of the menu and the menu button
        if (mobileMenuOpen && !event.target.closest('.mobile-menu-overlay') && !event.target.closest('.hamburger-menu')) {
            setMobileMenuOpen(false);
        }

        // Close the avatar menu if clicking outside of the menu and the user controls
        if (avatarMenuOpen && !event.target.closest('.mobile-avatar-menu-overlay') && !event.target.closest('.user-controls')) {
            setAvatarMenuOpen(false);
        }
    };

    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
        window.removeEventListener('resize', handleResize);
        document.removeEventListener('mousedown', handleOutsideClick);
    };
}, [mobileMenuOpen, avatarMenuOpen]);



 // Generate a random color for the avatar background
 const getRandomColor = () => {
    // Check if a color already exists in local storage
    const savedColor = localStorage.getItem('userColor');
  
    if (savedColor) {
      // If a color exists, return it
      return savedColor;
    } else {
      // If no color is stored, generate a new one
      const randomColor = '#' + (Math.random().toString(16) + '000000').substring(2, 8);
      // Store the new color in local storage
      localStorage.setItem('userColor', randomColor);
      return randomColor;
    }
  };


// Component for the user avatar
const UserAvatar = () => {
    // Check if 'user' exists before trying to access its properties
    if (user) {
      if (user.avatar) {
        return <img src={user.avatar} alt={`${user.name}'s avatar`} className="user-avatar" referrerPolicy="no-referrer" />;
      } else {
        const initial = getUserInitial(); 
        const avatarStyle = {
          backgroundColor: 'rgb(29, 29, 29)',
          borderRadius: '50%',
          width: '40px',
          height: '40px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          
          fontSize: '1em',
          marginRight: '10px', // Adjust based on your design
        };
        return <div style={avatarStyle}>{initial}</div>;
      }
    } else {
      // If 'user' doesn't exist, you can return a default element, or null if you don't want anything rendered
      return null; // or some placeholder component
    }
  };
  

    // Inside your component's return statement

    return (
      <header>
        <nav>
          <div className="hamburger-menu" onClick={toggleMobileMenu}>
            {mobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
          </div>
          <div className="logo">
            <a href="/">
              <img src={mobileLogo} alt="Logo" className="mobile-logo" />
              <img src={desktopLogo} alt="Logo" className="desktop-logo" />
            </a>
          </div>
    
          <ul className="nav-links">
          <li><Link to="/use-cases">{t('about')}</Link></li>
          <li><Link to="/pricing">{t('pricing')}</Link></li>
          <li><Link to="/blog">{t('blog')}</Link></li>
          <li><Link to="/choice">{t('take_notes')}</Link></li>
          
          </ul>
    
          <div className="user-controls">
            {user ? (
              <>
                <div onClick={handleClick}>
                  <UserAvatar />
                </div>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}><Link to="/settings">{t('user_settings')}</Link></MenuItem>
              <MenuItem onClick={handleClose}><Link to="/pricing">{t('subscribe')}</Link></MenuItem>
              <MenuItem onClick={handleClose}><Link to="/saved">{t('saved_notes')}</Link></MenuItem>
              
              <MenuItem onClick={handleClose}><Link to="/privacy">{t('privacy')}</Link></MenuItem>
              <MenuItem onClick={logout}>{t('logout')}</MenuItem>
                </Menu>
              </>
            ) : (
              <Link to="/login">{t('login')}</Link>
            )}
          </div>
        </nav>
        {avatarMenuOpen && (
          <div className="mobile-avatar-menu-overlay" onClick={() => setAvatarMenuOpen(false)}>
            <span className="mobile-menu-close" onClick={() => setAvatarMenuOpen(false)}>&times;</span>
            <ul className="mobile-avatar-nav-links">
              <li><Link to="/settings" onClick={() => setAvatarMenuOpen(false)}>{t('user_settings')}</Link></li>
              <li><Link to="/pricing" onClick={() => setAvatarMenuOpen(false)}>{t('subscribe')}</Link></li>
              <li><Link to="/saved" onClick={() => setAvatarMenuOpen(false)}>{t('saved_notes')}</Link></li>
              <li onClick={() => { logout(); setAvatarMenuOpen(false); }} >{t('logout')}</li>
            </ul>
          </div>
        )}
    
        {mobileMenuOpen && (
          <div className="mobile-menu-overlay" style={{ display: "block" }} onClick={toggleMobileMenu}>
            <ul className="mobile-nav-links">
              <li><Link to="/choice" onClick={toggleMobileMenu}>{t('take_notes')}</Link></li>
              <li><Link to="/pricing" onClick={toggleMobileMenu}>{t('pricing')}</Link></li>
              <li><Link to="/use-cases" onClick={toggleMobileMenu}>{t('about')}</Link></li>
              <li><Link to="/blog" onClick={toggleMobileMenu}>{t('blog')}</Link></li>
              <Link className='privacy-header' to="/privacy" onClick={toggleMobileMenu}>{t('privacy')}</Link>
            </ul>
          </div>
        )}
      </header>
    );
    
  };
  
  export default Header;