import React from 'react';
import './ModelsPage.css';
import hynoteClip from './hynote_clip2.mp4';
import { useTranslation } from 'react-i18next';

const ModelsPage = () => {
    const { t } = useTranslation();

    return (
      <div className="about-container">
        <div className="video-background">
          <video autoPlay loop muted playsInline className="background-video">
            <source src={hynoteClip} type="video/mp4" />
          </video>
        </div>
        <h1 className="about-title">{t('about_hynote_title')}</h1>
        <div className="about-content">
          <p>{t('about_hynote_intro')}</p>
          <p>
            <strong>{t('features')}</strong>
            <ul>
              <li>{t('intuitive_transcription')}</li>
              <li>{t('automatic_formatting')}</li>
              <li>{t('cloud_sync')}</li>
            </ul>
          </p>
          <p>{t('hynote_innovation')}</p>
        </div>
      </div>
    );
};

export default ModelsPage;
