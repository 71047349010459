// UseCasePage.js
import React from 'react';
import { Link } from 'react-router-dom';
import './UseCasePage.css'; // Make sure this points to your CSS file with correct styles
import { useTranslation } from 'react-i18next';
// Import the images (make sure the paths are correct)
import stock1 from './stock1.webp';
import stock2 from './stock2.webp';
import stock3 from './stock3.webp';

const UseCasePage = () => {
    const { t } = useTranslation();

  return (
    <div className="page-container">
      <h1 className="page-header">Hynote is Useful for Everyone</h1>
      <h2 className="page-subheader">From Content Creators, to Students, Doctors, Teachers and many more!</h2>
      <div className="use-cases-container">
        <div className="use-case">
          <div className="use-case-card">
            <Link to="/use-cases/subpage1" className="use-case-link">
              <h3>Content Creators</h3>
            </Link>
          </div>
          <img src={stock1} alt="Content Creator" className="use-case-image"/>
        </div>
        <div className="use-case">
          <div className="use-case-card">
            <Link to="/use-cases/subpage2" className="use-case-link">
              <h3>Students</h3>
            </Link>
          </div>
          <img src={stock2} alt="Student" className="use-case-image"/>
        </div>
        <div className="use-case">
          <div className="use-case-card">
            <Link to="/use-cases/subpage3" className="use-case-link">
              <h3>Medical Professionals</h3>
            </Link>
          </div>
          <img src={stock3} alt="Medical Professional" className="use-case-image"/>
        </div>
        {/* ... other links to your subpages wrapped in use-case classes */}
      </div>
      <div className="about-container">
        <h1 className="about-title">{t('about_hynote_title')}</h1>
        <div className="about-content">
          <p>{t('about_hynote_intro')}</p>
          <p>
            <strong>{t('features')}</strong>
            <ul>
              <li>{t('intuitive_transcription')}</li>
              <li>{t('automatic_formatting')}</li>
              <li>{t('cloud_sync')}</li>
            </ul>
          </p>
          <p>
            <strong>Planned {t('features')}</strong>
            <ul>
              <li>Take notes out of PHOTOS</li>
              <li>Random news article scriptwriting</li>
              <li>New and improved note saving and editing</li>
              <li>Custom and Easy to use editor</li>
              <li>Much more...</li>
            </ul>
          </p>
          <p>{t('hynote_innovation')}</p>
        </div>
      </div>
    </div>
  );
}

export default UseCasePage;
