import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          //Price Table
          "basic_plan": "Basic Plan",
          "full_plan": "Full Plan",
          "yearly_plan": "Yearly Plan",
          "basic_price": "$4 / month",
          "full_price": "$10 / month",
          "premium_price": "$20 / month",
          "price": "Price",
          "character_limit": "Character Limit",
          "voice_limit": "Voice Limit",
          //homepage SEO
          "home_page_title": "Hynote - Revolutionizing Note-Taking with AI Voice Transcription Transcription Technology",
          "home_page_description": "Effortlessly convert Voice Transcription to structured documents and expand ideas into comprehensive texts with our innovative AI app.",
          "blog": "Blog",
          "reduced_plan": "Reduced Plan*",
          "monthly_plan": "Monthly Plan",
          "yearly_plan": "Yearly Plan",
          "yearly_price": "$40.00 / year",
          "reduced_price": "$2.00 / Month",
          "tryfreenow": "Try for 3 Days",
          "youwillbecharged": "You will be charged $4/mo after the trial period ends",
          "monthly_price": "$4.00 / Month",
          "reduced_priceINDIA": "₹120/माह",
          "monthly_priceINDIA": "₹400/माह",
          "subscribe": "Subscribe",
          "note_organization": "Revolutionize Your Note Organization",
          "voice_transcription": "45 minutes of voice-to-text transcription per day*",
          "unlimited_access": "Gain access to all of our current and planned features!",
          "priority_support": "Personal custom support from developer(hynoteai@gmail.com)",
          "character_limit": "10000 character input per day for summarization*",
          "future_updates": "Brainstorm uses character count x 20 per input due to the longer outputs",
          "reduced_tag": "* For reduced, 3000 character limit, and 20 minutes of voice to text.",
          "subscription_success": "Subscription to starter plan successful!",
          "manage_billing": "Manage your billing information",
          "all_set": "You're all set!",
          "welcome_message": "Welcome aboard. You're ready to start using the app.",
          "gotosettings": "To manage your subscription, go to the User Settings page.",
          "record_and_transcribe": "Record and Transcribe",
    "start_typing_recording": "Start typing or recording your notes, then swipe left to proceed to the next step!",
    "current_character_count": "Current Character Count",
    "save": "Save",
    "edit_final_touches": "Click below to make your final touches before exporting!",
    "elevate_note_taking": "Elevate Your Note-Taking",//Homepage
      "experience_next_level": "Experience Next-Level Digital Transcription, Summarization, and Organization all in one place.",
      "start_now": "Start Now",
      "value_representation_alt": "AI-powered note-taking and summarization tool, Hynote, for efficient information management. Voice Transcription.", // Alt text for the image
      "session_character_count": "Session Character Count",
      "total_used_characters": "Total Used Characters",
      "total_characters": "Total",
      "export_to_docx": "Export to DOCX",
      "export_to_pdf": "Export to PDF",
      "export_to_html": "Export to HTML",
      "save_to_cloud": "Save to Cloud",
      "calibration": "Calibration",
      "make_sure_no_info_cut_off": "Make sure no information is cut off!",
      "character_count": "Character Count",
      "drag_markers_to_adjust": "Drag the markers above to adjust your notes!",
      "or_scroll_down_to_finish": "(Or, scroll down to finish and summarize your notes!)",
      "summarize": "Summarize",
      "enter_document_title": "Enter Document Title",
      "export_and_save": "Export and Save",
      "choose_to_export_or_save": "Here, you can export and save your finished notes!",
      "edit": "Edit",
      "click_below_to_make_your_final_touches": "Click below or press the pencil button below to make your final touches!",
          //Header
          "about": "Uses",
          "pricing": "Pricing",
          "take_notes": "Start Writing",
          "login": "Login",
          "user_settings": "User Settings",
          "subscribe": "Subscribe",
          "saved_notes": "Saved Notes",
          "logout": "Logout",
          //Voice Transcription languages
          "english": "English",
          "spanish": "Spanish",
          "spanish_latam": "Spanish(Latin American)",
          "hindi": "Hindi",
          "french": "French",
          "portuguese": "Portuguese",
          "portuguese-br": "Brazilian Portuguese",
          //User settings
          "subscription_settings": "Subscription Settings",
          "cancel_subscription": "Cancel Subscription",
          "payment_info": "Update Payment Info",
          "password_and_security": "Password and Security",
          "current_password": "Current Password",
          "new_password": "New Password",
          "change_password": "Change Password",
          //Loginb
          "username":"Username",
          "password":"Password",
          "dont_have_an_account": "Don't have an account?",
          "signup": "Sign Up",
          "forgot_password": "Forgot/Lost Password?",
          //Register:
      "register_success_message": "Signup successful. Please check your email to confirm your registration.",
      "register_failed_message": "Signup failed. Please try again.",
      "email": "Email",
      //Forgot Pass
      "enter_your_email": "Enter your email",
      "send_reset_link": "Send Reset Link",
      "forgot_success_message": "If an account with that email exists, we have sent a password reset link.",
      "forgot_error_message":" An error occurred. Please try again later.",
      //Abourt
        "about_hynote_title": "About Hynote",
        "about_hynote_intro": "Welcome to Hynote - your ultimate solution for turning spoken words into well-organized, structured notes. Our app is designed to cater to professionals, students, and anyone who needs to capture and organize their thoughts efficiently. Hynote uses advanced AI technology to transcribe your notes, ensuring accuracy and ease of use.",
        "features": "Features:",
        "intuitive_transcription": "Intuitive Transcription: Speak naturally, and Hynote will transcribe your words into text, organizing them into coherent notes.",
        "automatic_formatting": "Automatic Formatting: Our AI understands the context and formats your notes with headings, bullet points, and numbering as needed.",
        "cloud_sync": "Cloud Sync: Your notes are saved on our servers and can be accessed from the user section.",
        "hynote_innovation": "At Hynote, we're constantly innovating to enhance your note-taking experience. With our continuous updates and improvements, Hynote is evolving to cater to a wider range of transcription needs, aiming to be the most user-friendly and efficient note-taking app available. Try Hynote today and experience the future of note transcription!",
      //Reset Pass
      "reset_password_title": "Reset Password",
      "no_reset_token": "No reset token provided.",
      "invalid_expired_token": "Invalid or expired reset token.",
      "error_validating_token": "An error occurred while validating the reset token.",
      "error_resetting_password": "An error occurred while resetting the password.",
      "password_reset_success": "Your password has been reset successfully.",
      "password_reset_fail": "Failed to reset password. Please try again later.",
      "enter_new_password": "Enter new password",
      "reset_password_button": "Reset Password",
      //Voice Transcription Alerts
      "susbcribe_message": "Due to the costs to run this service, you must subscribe to use the transcription feature. A free version is being planned for the near future.",
      "mic_error": "Error starting recording. Check to make sure you have a working microphone connected.",
      "mic_time_limit": "Recording time limit has been reached.",
      "inactive_sub1":"Your subscription is inactive.",
      "inactive_sub2":"Please subscribe",
      "inactive_sub3": "to access this feature! A free version is currently in development, however, in the meantime feel free to try out our free features!",
      "chunk_error1": "One or more of your chunks is an invalid size.",
      "chunk_error2": "Please change the size of the chunk in question and try again.",
      //Privacy
      "privacy": "Privacy Policy",
      //concept writeup
      "brainstorm": "Brainstorm",
      "writetopic": "Write down a word, concept or idea you would like a detailed writeup of.",
      "writetopic2": "Type in keywords and phrases separated by commas that you would like turned into a youtube script.",
      "brainstorm_title": "Brainstorm",
      "brainstorm_description": "Create detailed article writeups with only a few words. Perfect for Blogs or general writing for when you are out of ideas.",
      "summarize_title": "Summarize",
      "summarize_description": "Summarize spoken or written notes into well organized documents.",

        }
      },
      hi: {
        translation: {
          "reduced_plan": "मूल सदस्यता",
          "monthly_plan": "प्रो सदस्यता",
          "reduced_price": "$2.00/माह",
          "reduced_priceINDIA": "₹120/माह",
          "monthly_price": "$4.00/माह",
          "monthly_priceINDIA": "₹400/माह",
          "tryfreenow": "3 दिन तक प्रयास करें",
          "youwillbecharged": "परीक्षण अवधि समाप्त होने के बाद आपसे $4/महीने (USD) का शुल्क लिया जाएगा",
          "subscribe": "सदस्यता लें",
          "yearly_plan": "वार्षिक योजना",
          "yearly_price": "$40.00/वर्ष",
          "reduced_tag": "क्षेत्र के आधार पर सीमाएँ और मूल्य भिन्न-भिन्न होते हैं",
          "note_organization": "अपने नोट संगठन में क्रांति",
          "voice_transcription": "वॉइस-टू-टेक्स्ट ट्रांस रिकॉर्ड के लिए प्रतिदिन 30 मिनट (मूल 20 के लिए)",
          "unlimited_access": "सभी वर्तमान और भविष्य की सुविधाओं तक असीमित पहुँच",
          "priority_support": "डेवलपर से व्यक्तिगत कस्टम समर्थन(hynoteai@gmail.com)",
          "character_limit": "संक्षेपण के लिए प्रति दिन 8000 अक्षर इनपुट (बेसिक के लिए 3000)*",
          "future_updates": "हम इस सीमा को अनुकूलित करने के तरीकों पर सक्रिय रूप से काम कर रहे हैं, और भविष्य में इसे बढ़ाया जाएगा।",
          "subscription_success": "स्टार्टर योजना की सदस्यता सफल!",
          "manage_billing": "अपनी बिलिंग जानकारी प्रबंधित करें",
          "all_set": "आप तैयार हैं!",
          "welcome_message": "बोर्ड पर आपका स्वागत है। आप अब ऐप का पूरा उपयोग कर सकते हैं।",
          "gotosettings": "अपनी सदस्यता को प्रबंधित करने के लिए, उपयोगकर्ता सेटिंग्स पृष्ठ पर जाएं।",
          "record_and_transcribe": "रिकॉर्ड और ट्रांसक्राइब करें",
          "record_and_transcribe": "रिकॉर्ड और ट्रांसक्राइब करें",
          "start_typing_recording": "अपने नोट्स टाइप करना या रिकॉर्डिंग शुरू करें, फिर अगले चरण के लिए बाएँ स्वाइप करें!",
          "current_character_count": "वर्तमान अक्षर संख्या",
          "save": "सहेजें",
          "calibration": "कैलिब्रेशन",
          "make_sure_no_info_cut_off": "सुनिश्चित करें कि कोई जानकारी कट नहीं रही है!",
          "edit": "संपादित करें",
          "click_below_to_make_your_final_touches": "निर्यात से पहले अपने अंतिम स्पर्श करने के लिए नीचे क्लिक करें!",
          "export_and_save": "निर्यात और सहेजें",
          "choose_to_export_or_save": "आप यहाँ अपने पूर्ण नोट को निर्यात या सहेज सकते हैं",
                "elevate_note_taking": "अपने नोट-लेने को ऊंचाई पर ले जाएं",//Homepage Shit
                "experience_next_level": "डिजिटल ट्रांसक्रिप्शन, सारांशन, और संगठन का अगला स्तर अनुभव करें, सभी एक ही स्थान पर।",
                "start_now": "अभी शुरू करें",
                "value_representation_alt": "मूल्य प्रतिनिधित्व", // Alt text for the image
                "calibration": "मानकीकरण",
          "make_sure_no_info_cut_off": "सुनिश्चित करें कि कोई जानकारी कट नहीं रही है!",
          "character_count": "अक्षर संख्या",
          "drag_markers_to_adjust": "अपने नोट्स समायोजित करने के लिए ऊपर के मार्करों को खींचें!",
          "or_scroll_down_to_finish": "(या अपने नोट्स को समाप्त करने और सारांशित करने के लिए नीचे स्क्रॉल करें!)",
          "summarize": "सारांशित करें",
          "session_character_count": "सत्र अक्षर गणना",
      "total_used_characters": "कुल प्रयुक्त अक्षर",
      "total_characters": "कुल",
      "export_to_docx": "डॉक्स में निर्यात करें",
      "export_to_pdf": "पीडीएफ में निर्यात करें",
      "export_to_html": "एचटीएमएल में निर्यात करें",
      "save_to_cloud": "क्लाउड में सहेजें",
      "enter_document_title": "दस्तावेज़ शीर्षक दर्ज करें",
          //Header
      "about": "बारे में",
      "pricing": "मूल्य निर्धारण",
      "take_notes": "नोट्स लें",
      "login": "लॉग इन करें",
      "user_settings": "उपयोगकर्ता सेटिंग्स",
      "subscribe": "सब्सक्राइब करें",
      "saved_notes": "सहेजे गए नोट्स",
      "logout": "लॉग आउट",
      //Voice Transcription languages
      "english": "अंग्रेज़ी",
      "spanish": "स्पैनिश",
      "spanish_latam": "स्पैनिश (लैटिन अमेरिकन)",
      "hindi": "हिंदी",
      "french": "फ़्रेंच",
      "portuguese": "पुर्तगाली",
      "portuguese-br": "ब्राजिलियन पुर्तगाली",
      //User settings
      "subscription_settings": "सदस्यता सेटिंग्स",
      "cancel_subscription": "सदस्यता रद्द करें",
      "payment_info": "भुगतान जानकारी अपडेट करें",
      "password_and_security": "पासवर्ड और सुरक्षा",
      "current_password": "वर्तमान पासवर्ड",
      "new_password": "नया पासवर्ड",
      "change_password": "पासवर्ड बदलें",
      //Login
      "username": "उपयोगकर्ता नाम",
      "password": "पासवर्ड",
      "dont_have_an_account": "खाता नहीं है?",
      "signup": "साइन अप करें",
      "forgot_password": "पासवर्ड भूल गए/खो गए?",
      //Register:
      "register_success_message": "साइन अप सफल हुआ। कृपया अपने रजिस्ट्रेशन की पुष्टि करने के लिए अपने ईमेल की जाँच करें।",
      "register_failed_message": "साइन अप विफल हुआ। कृपया पुनः प्रयास करें।",
      "email": "ईमेल",
      //Forgot Pass
      "enter_your_email": "अपना ईमेल दर्ज करें",
      "send_reset_link": "रीसेट लिंक भेजें",
      "forgot_success_message": "यदि उस ईमेल के साथ एक खाता मौजूद है, तो हमने एक पासवर्ड रीसेट लिंक भेज दी है।",
      "forgot_error_message": "एक त्रुटि आई है। कृपया बाद में पुन: कोशिश करें।",
      //about
        "about_hynote_title": "हाइनोट के बारे में",
        "about_hynote_intro": "हाइनोट में आपका स्वागत है - आपके बोले हुए शब्दों को अच्छी तरह से संगठित, संरचित नोट्स में बदलने का आपका अंतिम समाधान। हमारा ऐप पेशेवरों, छात्रों और उन सभी के लिए डिज़ाइन किया गया है जिन्हें अपने विचारों को कुशलतापूर्वक पकड़ने और व्यवस्थित करने की जरूरत है। हाइनोट उन्नत AI प्रौद्योगिकी का उपयोग करता है ताकि आपके नोट्स को सटीकता और उपयोग में आसानी के साथ ट्रांसक्राइब किया जा सके।",
        "features": "विशेषताएँ:",
        "intuitive_transcription": "सहज ट्रांसक्रिप्शन: स्वाभाविक रूप से बोलें, और हाइनोट आपके शब्दों को टेक्स्ट में बदल देगा, उन्हें सुसंगत नोट्स में व्यवस्थित करेगा।",
        "automatic_formatting": "स्वचालित प्रारूपण: हमारा AI संदर्भ को समझता है और आवश्यकतानुसार आपके नोट्स को शीर्षक, बुलेट पॉइंट्स, और नंबरिंग के साथ प्रारूपित करता है।",
        "cloud_sync": "क्लाउड सिंक: आपके नोट्स हमारे सर्वर पर सहेजे जाते हैं और उपयोगकर्ता अनुभाग से एक्सेस किए जा सकते हैं।",
        "hynote_innovation": "हाइनोट में, हम आपके नोट-लेने के अनुभव को बढ़ाने के लिए लगातार नवाचार कर रहे हैं। हमारे निरंतर अपडेट और सुधारों के साथ, हाइनोट अधिक प्रकार के ट्रांसक्रिप्शन की आवश्यकताओं की सेवा करने के लिए विकसित हो रहा है, जिससे यह सबसे उपयोगकर्ता-अनुकूल और कुशल नोट-लेने वाला ऐप बनने की दिशा में अग्रसर है। आज ही हाइनोट का प्रयास करें और नोट ट्रांसक्रिप्शन के भविष्य का अनुभव करें!",
        //Reset Pass
        "reset_password_title": "पासवर्ड रीसेट करें",
        "no_reset_token": "रीसेट टोकन प्रदान नहीं किया गया।",
        "invalid_expired_token": "अवैध या समाप्त हो चुका रीसेट टोकन।",
        "error_validating_token": "रीसेट टोकन को मान्य करते समय एक त्रुटि हुई।",
        "error_resetting_password": "पासवर्ड रीसेट करते समय एक त्रुटि हुई।",
        "password_reset_success": "आपका पासवर्ड सफलतापूर्वक रीसेट कर दिया गया है।",
        "password_reset_fail": "पासवर्ड रीसेट करने में असफल। कृपया बाद में पुनः प्रयास करें।",
        "enter_new_password": "नया पासवर्ड दर्ज करें",
        "reset_password_button": "पासवर्ड रीसेट करें",
          //VT Alerts
          "susbcribe_message": "इस सेवा को चलाने के लिए आपको सदस्यता लेनी होगी क्योंकि इस स्थानिकरण की लागतें होती हैं। एक मुफ्त संस्करण को निकट भविष्य में योजना बना रही है।",
          "mic_error": "रिकॉर्डिंग शुरू करने में त्रुटि हुई। सुनिश्चित करें कि आपके पास काम करने वाला माइक्रोफ़ोन कनेक्ट है।",
          "mic_time_limit": "रिकॉर्डिंग समय सीमा पहुंच गई है।",
          "inactive_sub1": "आपकी सदस्यता निष्क्रिय है।",
          "inactive_sub2": "कृपया सदस्यता लें",
          "inactive_sub3": "इस सुविधा तक पहुँचने के लिए! एक मुफ्त संस्करण वर्तमान में विकसित किया जा रहा है, हालांकि इस बीच, कृपया हमारी मुफ्त सुविधाओं का अनुभव करने के लिए आज़माएं।",
          "chunk_error1": "आपके एक या एक से अधिक चंक मान्य आ,कार में नहीं है।",
          "chunk_error2": "कृपया सवाल के आकार को बदलें और पुनः प्रयास करें।",
          "brainstorm_title": "ब्रेनस्टॉर्म",
          "brainstorm_description": "केवल एक शब्द प्रेरणा से लाभदायक ब्लॉग लेख लिखें।",
          "summarize_title": "सारांश बनाएं",
          "summarize_description": "मौखिक या लिखित नोट्स का संक्षिप्त और व्यवस्थित दस्तावेज़ में सारांश बनाएं।",
        }
      }
    },
    es: {
    translation: {
      //homepage SEO
      "home_page_title": "Hynote - Revolucionando la toma de notas con tecnología de transcripción de voz con IA",
      "home_page_description": "Convierte sin esfuerzo la transcripción de voz en documentos estructurados y expande ideas en textos completos con nuestra innovadora aplicación de IA.",
      "blog": "Blog",
      "reduced_plan": "Plan Reducido*",
      "monthly_plan": "Plan Mensual",
      "yearly_plan": "Plan Anual",
      "yearly_price": "$40.00 USD / año",
      "reduced_price": "$2.00 USD / Mes",
      "tryfreenow": "Prueba durante 3 días",
      "youwillbecharged": "Se te cobrará $4 USD/mes después del periodo de prueba",
      "monthly_price": "$4.00 USD / Mes",
      "reduced_priceINDIA": "₹120/mes",
      "monthly_priceINDIA": "₹400/mes",
      "subscribe": "Suscribirse",
      "note_organization": "Revoluciona la organización de tus notas",
      "voice_transcription": "45 minutos de transcripción de voz a texto por día*",
      "unlimited_access": "Acceso a todas las características actuales y futuras",
      "priority_support": "Soporte personalizado del desarrollador (hynoteai@gmail.com)",
      "character_limit": "Límite de 10000 caracteres por día para resumen*",
      "future_updates": "Brainstorm usa un conteo de caracteres x 20 por entrada debido a los resultados más largos",
      "reduced_tag": "* Para el plan reducido, límite de 3000 caracteres y 20 minutos de voz a texto.",
      "subscription_success": "¡Suscripción al plan de inicio exitosa!",
      "manage_billing": "Administra tu información de facturación",
      "all_set": "¡Todo listo!",
      "welcome_message": "Bienvenido a bordo. Estás listo para comenzar a usar la aplicación.",
      "gotosettings": "Para gestionar tu suscripción, ve a la página de Configuración del Usuario.",
      "record_and_transcribe": "Grabar y Transcribir",
      "start_typing_recording": "Comienza a escribir o grabar tus notas, luego desliza a la izquierda para continuar con el siguiente paso.",
      "current_character_count": "Conteo Actual de Caracteres",
      "save": "Guardar",
      "edit_final_touches": "¡Haz clic abajo para dar los últimos retoques antes de exportar!",
      "elevate_note_taking": "Eleva tu Toma de Notas", //Homepage
      "experience_next_level": "Experimenta la Transcripción Digital, Resumen y Organización de Siguiente Nivel, todo en un solo lugar.",
      "start_now": "Comenzar Ahora",
      "value_representation_alt": "Herramienta de toma de notas y resumen impulsada por IA, Hynote, para una gestión eficiente de la información. Transcripción de Voz.", // Texto alternativo para la imagen
      "session_character_count": "Conteo de Caracteres de la Sesión",
      "total_used_characters": "Caracteres Totales Utilizados",
      "total_characters": "Total",
      "export_to_docx": "Exportar a DOCX",
      "export_to_pdf": "Exportar a PDF",
      "export_to_html": "Exportar a HTML",
      "save_to_cloud": "Guardar en la Nube",
      "calibration": "Calibración",
      "make_sure_no_info_cut_off": "¡Asegúrate de que no se corte ninguna información!",
      "character_count": "Conteo de Caracteres",
      "drag_markers_to_adjust": "Arrastra los marcadores de arriba para ajustar tus notas.",
      "or_scroll_down_to_finish": "(O, desplázate hacia abajo para terminar y resumir tus notas.)",
      "summarize": "Resumir",
      "enter_document_title": "Introduce el Título del Documento",
      "export_and_save": "Exportar y Guardar",
      "choose_to_export_or_save": "Aquí puedes exportar y guardar tus notas finalizadas.",
      "edit": "Editar",
      "click_below_to_make_your_final_touches": "Haz clic abajo o presiona el botón de lápiz para dar los últimos retoques.",
      //Header
      "about": "Acerca de",
      "pricing": "Precios",
      "take_notes": "Tomar Notas",
      "login": "Iniciar Sesión",
      "user_settings": "Configuración del Usuario",
      "subscribe": "Suscribirse",
      "saved_notes": "Notas Guardadas",
      "logout": "Cerrar Sesión",
      //Idiomas de Transcripción de Voz
      "english": "Inglés",
      "spanish": "Español",
      "spanish_latam": "Español (Latinoamericano)",
      "hindi": "Hindi",
      "french": "Francés",
      "portuguese": "Portugués",
      "portuguese-br": "Portugués Brasileño",
      //Configuración de Usuario
      "subscription_settings": "Configuración de Suscripción",
      "cancel_subscription": "Cancelar Suscripción",
      "payment_info": "Actualizar Información de Pago",
      "password_and_security": "Contraseña y Seguridad",
      "current_password": "Contraseña Actual",
      "new_password": "Nueva Contraseña",
      "change_password": "Cambiar Contraseña",
      //Login
      "username": "Nombre de Usuario",
      "password": "Contraseña",
      "dont_have_an_account": "¿No tienes una cuenta?",
      "signup": "Registrarse",
      "forgot_password": "¿Olvidaste/Perdiste la Contraseña?",
      //Registro
      "register_success_message": "Registro exitoso. Por favor revisa tu correo electrónico para confirmar tu registro.",
      "register_failed_message": "El registro ha fallado. Por favor, intenta de nuevo.",
      "email": "Correo Electrónico",
      //Contraseña Olvidada
      "enter_your_email": "Ingresa tu correo electrónico",
      "send_reset_link": "Enviar Enlace de Restablecimiento",
      "forgot_success_message": "Si existe una cuenta con ese correo electrónico, hemos enviado un enlace para restablecer la contraseña.",
      "forgot_error_message": "Ocurrió un error. Por favor, intenta más tarde.",
      //Acerca de
      "about_hynote_title": "Acerca de Hynote",
      "about_hynote_intro": "Bienvenido a Hynote - tu solución definitiva para convertir palabras habladas en notas bien organizadas y estructuradas. Nuestra aplicación está diseñada para atender a profesionales, estudiantes y cualquier persona que necesite capturar y organizar sus pensamientos de manera eficiente. Hynote utiliza tecnología de IA avanzada para transcribir tus notas, garantizando precisión y facilidad de uso.",
      "features": "Características:",
      "intuitive_transcription": "Transcripción Intuitiva: Habla con naturalidad y Hynote transcribirá tus palabras en texto, organizándolas en notas coherentes.",
      "automatic_formatting": "Formato Automático: Nuestra IA comprende el contexto y formatea tus notas con encabezados, viñetas y numeración según sea necesario.",
      "cloud_sync": "Sincronización en la Nube: Tus notas se guardan en nuestros servidores y se pueden acceder desde la sección de usuario.",
      "hynote_innovation": "En Hynote, estamos constantemente innovando para mejorar tu experiencia de toma de notas. Con nuestras actualizaciones y mejoras continuas, Hynote está evolucionando para atender una gama más amplia de necesidades de transcripción, con el objetivo de ser la aplicación de toma de notas más amigable y eficiente disponible. ¡Prueba Hynote hoy y experimenta el futuro de la transcripción de notas!",
      //Restablecer Contraseña
      "reset_password_title": "Restablecer Contraseña",
      "no_reset_token": "No se proporcionó token de restablecimiento.",
      "invalid_expired_token": "Token de restablecimiento inválido o caducado.",
      "error_validating_token": "Ocurrió un error al validar el token de restablecimiento.",
      "error_resetting_password": "Ocurrió un error al restablecer la contraseña.",
      "password_reset_success": "Tu contraseña ha sido restablecida exitosamente.",
      "password_reset_fail": "Falló el restablecimiento de la contraseña. Por favor, intenta más tarde.",
      "enter_new_password": "Ingresa una nueva contraseña",
      "reset_password_button": "Restablecer Contraseña",
      //Alertas de Transcripción de Voz
      "susbcribe_message": "Debido a los costos de ejecutar este servicio, debes suscribirte para usar la función de transcripción. Una versión gratuita está planeada para un futuro cercano.",
      "mic_error": "Error al iniciar la grabación. Verifica para asegurarte de que tienes un micrófono funcional conectado.",
      "mic_time_limit": "Se ha alcanzado el límite de tiempo de grabación.",
      "inactive_sub1": "Tu suscripción está inactiva.",
      "inactive_sub2": "Por favor, suscríbete",
      "inactive_sub3": "para acceder a esta función. Actualmente se está desarrollando una versión gratuita, sin embargo, mientras tanto, ¡siéntete libre de probar nuestras características gratuitas!",
      "chunk_error1": "Uno o más de tus fragmentos tienen un tamaño inválido.",
      "chunk_error2": "Por favor, cambia el tamaño del fragmento en cuestión y vuelve a intentarlo.",
      //Privacidad
      "privacy": "Política de Privacidad",
      //redacción de conceptos
      "brainstorm": "Lluvia de Ideas",
      "writetopic": "Escribe una palabra, concepto o idea sobre la cual te gustaría un detallado artículo.",
      "brainstorm_title": "Lluvia de Ideas",
      "brainstorm_description": "Escribe artículos de blog rentables con solo una palabra clave.",
      "summarize_title": "Resumir",
      "summarize_description": "Resumir notas habladas o escritas en documentos bien organizados.",
     }
    },
    pt: {
      translation: {
        //homepage SEO
        "home_page_title": "Hynote - Revolucionando a Anotação com Tecnologia de Transcrição de Voz por IA",
        "home_page_description": "Converta facilmente Transcrição de Voz em documentos estruturados e expanda ideias em textos abrangentes com nosso aplicativo inovador de IA.",
        "blog": "Blog",
        "reduced_plan": "Plano Reduzido*",
        "monthly_plan": "Plano Mensal",
        "yearly_plan": "Plano Anual",
        "yearly_price": "$40.00 / ano",
        "reduced_price": "$2.00 / Mês",
        "tryfreenow": "Experimente por 3 Dias",
        "youwillbecharged": "Você será cobrado $4/mês após o período de teste",
        "monthly_price": "$4.00 / Mês",
        "reduced_priceINDIA": "₹120/mês",
        "monthly_priceINDIA": "₹400/mês",
        "subscribe": "Assinar",
        "note_organization": "Revolucione a Organização das Suas Notas",
        "voice_transcription": "45 minutos de transcrição de voz para texto por dia*",
        "unlimited_access": "Acesso a todos os recursos atuais e futuros",
        "priority_support": "Suporte personalizado do desenvolvedor (hynoteai@gmail.com)",
        "character_limit": "Limite de 10000 caracteres por dia para resumo*",
        "future_updates": "Brainstorm usa contagem de caracteres x 20 por entrada devido aos resultados mais longos",
        "reduced_tag": "* Para reduzido, limite de 3000 caracteres e 20 minutos de voz para texto.",
        "subscription_success": "Assinatura do plano inicial bem-sucedida!",
        "manage_billing": "Gerencie suas informações de cobrança",
        "all_set": "Tudo pronto!",
        "welcome_message": "Bem-vindo a bordo. Você está pronto para começar a usar o aplicativo.",
        "gotosettings": "Para gerenciar sua assinatura, vá para a página de Configurações do Usuário.",
        "record_and_transcribe": "Gravar e Transcrever",
        "start_typing_recording": "Comece a digitar ou gravar suas notas e depois deslize para a esquerda para prosseguir para a próxima etapa!",
        "current_character_count": "Contagem Atual de Caracteres",
        "save": "Salvar",
        "edit_final_touches": "Clique abaixo para fazer os ajustes finais antes de exportar!",
        "elevate_note_taking": "Eleve Sua Anotação",//Homepage
        "experience_next_level": "Experimente Transcrição Digital, Resumo e Organização de Último Nível, tudo em um só lugar.",
        "start_now": "Começar Agora",
        "value_representation_alt": "Ferramenta de anotação e resumo impulsionada por IA, Hynote, para gerenciamento eficiente de informações. Transcrição de Voz.", // Texto alternativo para a imagem
        "session_character_count": "Contagem de Caracteres da Sessão",
        "total_used_characters": "Total de Caracteres Usados",
        "total_characters": "Total",
        "export_to_docx": "Exportar para DOCX",
        "export_to_pdf": "Exportar para PDF",
        "export_to_html": "Exportar para HTML",
        "save_to_cloud": "Salvar na Nuvem",
        "calibration": "Calibração",
        "make_sure_no_info_cut_off": "Certifique-se de que nenhuma informação seja cortada!",
        "character_count": "Contagem de Caracteres",
        "drag_markers_to_adjust": "Arraste os marcadores acima para ajustar suas notas!",
        "or_scroll_down_to_finish": "(Ou, role para baixo para terminar e resumir suas notas!)",
        "summarize": "Resumir",
        "enter_document_title": "Insira o Título do Documento",
        "export_and_save": "Exportar e Salvar",
        "choose_to_export_or_save": "Aqui, você pode exportar e salvar suas notas finalizadas!",
        "edit": "Editar",
        "click_below_to_make_your_final_touches": "Clique abaixo ou pressione o botão de lápis abaixo para fazer os ajustes finais!",
        //Header
        "about": "Sobre",
        "pricing": "Preços",
        "take_notes": "Fazer Anotações",
        "login": "Entrar",
        "user_settings": "Configurações do Usuário",
        "subscribe": "Assinar",
        "saved_notes": "Notas Salvas",
        "logout": "Sair",
        //Voice Transcription languages
        "english": "Inglês",
        "spanish": "Espanhol",
        "spanish_latam": "Espanhol (América Latina)",
        "hindi": "Hindi",
        "french": "Francês",
        "portuguese": "Português",
        "portuguese-br": "Português Brasileiro",
        //User settings
        "subscription_settings": "Configurações de Assinatura",
        "cancel_subscription": "Cancelar Assinatura",
        "payment_info": "Atualizar Informações de Pagamento",
        "password_and_security": "Senha e Segurança",
        "current_password": "Senha Atual",
        "new_password": "Nova Senha",
        "change_password": "Alterar Senha",
        //Login
        "username":"Nome de Usuário",
        "password":"Senha",
        "dont_have_an_account": "Não tem uma conta?",
        "signup": "Inscrever-se",
        "forgot_password": "Esqueceu/Perdeu a Senha?",
        //Register:
        "register_success_message": "Cadastro bem-sucedido. Por favor, verifique seu e-mail para confirmar seu registro.",
        "register_failed_message": "Falha no cadastro. Por favor, tente novamente.",
        "email": "E-mail",
        //Forgot Pass
        "enter_your_email": "Insira seu e-mail",
        "send_reset_link": "Enviar Link de Redefinição",
        "forgot_success_message": "Se uma conta com esse e-mail existir, enviamos um link para redefinir a senha.",
        "forgot_error_message":" Ocorreu um erro. Por favor, tente novamente mais tarde.",
      //About
        "about_hynote_title": "Sobre Hynote",
        "about_hynote_intro": "Bem-vindo ao Hynote - sua solução definitiva para transformar palavras faladas em notas bem organizadas e estruturadas. Nosso aplicativo é projetado para atender a profissionais, estudantes e qualquer pessoa que precise capturar e organizar seus pensamentos de forma eficiente. Hynote usa tecnologia avançada de IA para transcrever suas notas, garantindo precisão e facilidade de uso.",
        "features": "Características:",
        "intuitive_transcription": "Transcrição Intuitiva: Fale naturalmente, e o Hynote transcreverá suas palavras em texto, organizando-as em notas coerentes.",
        "automatic_formatting": "Formatação Automática: Nossa IA entende o contexto e formata suas notas com títulos, pontos e numeração conforme necessário.",
        "cloud_sync": "Sincronização na Nuvem: Suas notas são salvas em nossos servidores e podem ser acessadas na seção do usuário.",
        "hynote_innovation": "Na Hynote, estamos constantemente inovando para aprimorar sua experiência de anotação. Com nossas atualizações e melhorias contínuas, o Hynote está evoluindo para atender a uma gama mais ampla de necessidades de transcrição, visando ser o aplicativo de anotações mais amigável e eficiente disponível. Experimente o Hynote hoje e vivencie o futuro da transcrição de notas!",
      //Reset Pass
        "reset_password_title": "Redefinir Senha",
        "no_reset_token": "Nenhum token de redefinição fornecido.",
        "invalid_expired_token": "Token de redefinição inválido ou expirado.",
        "error_validating_token": "Ocorreu um erro ao validar o token de redefinição.",
        "error_resetting_password": "Ocorreu um erro ao redefinir a senha.",
        "password_reset_success": "Sua senha foi redefinida com sucesso.",
        "password_reset_fail": "Falha ao redefinir a senha. Por favor, tente novamente mais tarde.",
        "enter_new_password": "Insira nova senha",
        "reset_password_button": "Redefinir Senha",
        //Alertas de Transcrição de Voz
        "susbcribe_message": "Devido aos custos para executar este serviço, você deve se inscrever para usar o recurso de transcrição. Uma versão gratuita está planejada para um futuro próximo.",
        "mic_error": "Erro ao iniciar a gravação. Verifique para garantir que você tem um microfone funcional conectado.",
        "mic_time_limit": "O limite de tempo de gravação foi atingido.",
        "inactive_sub1": "Sua assinatura está inativa.",
        "inactive_sub2": "Por favor, assine",
        "inactive_sub3": "para acessar este recurso. Uma versão gratuita está atualmente em desenvolvimento, no entanto, enquanto isso, sinta-se livre para experimentar nossos recursos gratuitos!",
        "chunk_error1": "Um ou mais dos seus fragmentos tem um tamanho inválido.",
        "chunk_error2": "Por favor, altere o tamanho do fragmento em questão e tente novamente.",
        //Privacidade
        "privacy": "Política de Privacidade",
        //redação de conceitos
        "brainstorm": "Brainstorm",
        "writetopic": "Escreva uma palavra, conceito ou ideia sobre a qual você gostaria de um artigo detalhado.",
        "brainstorm_title": "Brainstorm",
        "brainstorm_description": "Escreva artigos de blog lucrativos com apenas uma única palavra-chave.",
        "summarize_title": "Resumir",
        "summarize_description": "Resuma notas faladas ou escritas em documentos bem organizados.",
      }
    },
    fallbackLng: 'en',
    detection: {
      order: ['navigator', 'querystring', 'cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'], // Where to store the language preference
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
