// Login.js
import React, { useState, useContext } from 'react'; 
import { Link, useNavigate } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login'; // Import the react-google-login component
import { AuthContext } from './AuthContext';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import googleIcon from '../components/icons8-google-48.png';  // Adjust the path if necessary
import { useTranslation } from 'react-i18next';

//Style
import './Login.css';

const API_URL = process.env.REACT_APP_API_URL;

const Login = () => {
    const navigate = useNavigate();
    const [credentials, setCredentials] = useState({ username: '', password: '' });
    const { setUser } = useContext(AuthContext); // Access your context for user info and login function
    const [error, setError] = useState('');
    const { t } = useTranslation();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCredentials(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/login`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(credentials),
            });
    
            if (response.ok) {
                const data = await response.json();
    
                // Store the user data in local storage, similar to how it's done for Google login
                localStorage.setItem('user', JSON.stringify(data.user));
    
                // If you're also receiving a token, store it too
                if (data.token) {
                    localStorage.setItem('userToken', data.token);
                }
    
                setUser(data.user);  // This sets the user in your global context (if you're using one)
                navigate('/'); // Navigate to the dashboard
                setError('');
            } else {
                setError('Login failed. Please check your credentials. If you have not verified your account, please do so via email.');
            }
        } catch (error) {
            console.error('An error occurred during login', error);
            setError('An error occurred. Please try again.');
        }
    };

// Define the custom Google login logic
// Define the custom Google login logic
const googleLogin = useGoogleLogin({
    clientId: '1096061175649-3gnhiqh8olncskp0koloktb4uucvsj8j.apps.googleusercontent.com', // replace with your actual client ID
    onSuccess: (codeResponse) => {
        console.log("Code response:", codeResponse);

        // Check if the response includes the authorization code
        const authCode = codeResponse.code; // This is the one-time code you send to your server

        if (!authCode) {
            console.error('No authorization code found in the response.');
            return;
        }

        // Send the authorization code to the server
        fetch(`${API_URL}/authenticate`, { // Make sure this URL points to your server-side auth endpoint
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ code: authCode }), // Send the code
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
            }
            return response.json();
        })
        .then(data => {
            console.log('Server response:', data);
            // Handle the server's response here, typically by setting up the user session
            if (data.user) {
                setUser(data.user);
                localStorage.setItem('user', JSON.stringify(data.user));
                if (data.token) {
                    localStorage.setItem('userToken', data.token);
                }
                navigate('/');
            } else {
                throw new Error('User not found in response.');
            }
        })
        .catch((error) => {
            console.error('Error during server authentication:', error);
        });
    },
    onError: (error) => {
        console.error('Login Failed:', error);
    },
    flow: 'auth-code',  // This is crucial for triggering the authorization code flow
});

    return (
        <div className="login-container">
        <form onSubmit={handleSubmit} className="login-form">
            <input 
                type="text" 
                name="username" 
                value={credentials.username} 
                onChange={handleChange} 
                placeholder={t('username')} 
                required 
            />
            <input 
                type="password" 
                name="password" 
                value={credentials.password} 
                onChange={handleChange} 
                placeholder={t('password')}  
                required 
            />
            <button className="button" type="submit">{t('login')}</button>
            {error && <p className="error-message">{error}</p>}
            <br></br> <br></br>
            {/* Google Sign-In Component */}
            <button type="button" className="googleButton" onClick={() => googleLogin()}>
                    <img src={googleIcon} alt="Google sign-in" className="google-icon" />
                    Sign in with Google
                </button>
                <br></br>
                <p>{t('dont_have_an_account')} <Link to="/Signup">{t('signup')} </Link></p>
                <br></br>
                <p><Link to="/forgot-password">{t('forgot_password')}</Link></p>
        </form>
        
    </div>
      
    );
};

export default Login;
