import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ChoicePage.css'; // Import the CSS file
import { useTranslation } from 'react-i18next';
import iconBrainstorm from './choiceIcon2.png'; // Update the path
import iconSummarize from './choiceIcon1.png'; // Update the path
import iconScriptWriter from './choiceIcon3.png'; // Update the path

const ChoicePage2 = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="choice-container">
          <div className="img-background">
        </div>
      <div className="choice" onClick={() => navigate('/yta')}>
  <div className="choice-title">Automatic</div>
  <img src={iconScriptWriter} alt="Automatic" className="choice-icon" />
  <div className="choice-box">Enter keywords and Hynote will write the entire script for you. Choose from short, long and medium form scripts.</div>
</div>
<div className="choice" onClick={() => navigate('/ytb')}>
  <div className="choice-title">Rough Draft Enhancer</div>
  <img src={iconScriptWriter} alt="Proofread" className="choice-icon" />
  <div className="choice-box">Write or speak your script rough drafts and Hynote will turn it into a well presented Youtube script! Perfect for if you have an idea but you don't feel like fleshing it out.</div>
</div>

    </div>
  );
};

export default ChoicePage2;
