import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from './AuthContext';
import './Settings.css';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';


const Settings = () => {
  const { user, setUser } = useContext(AuthContext);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordChangeMessage, setPasswordChangeMessage] = useState('');
  const [error, setError] = useState('');
  const { t } = useTranslation();
  const fetchSubscriptions = async () => {
    if (user) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user-subscriptions?userId=${user.id}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setSubscriptions(data.subscriptions);
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchSubscriptions();
  }, [user]);

  const CancelSubscription = async () => {
    if (window.confirm("Are you sure you want to cancel your subscription? This action cannot be undone, and you will not be refunded for the current billing period.")) {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/cancel-subscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ stripeCustomerId: user.stripeCustomerId, userId: user.id }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();
            if (result.success) {
                console.log('Subscription cancelled successfully');
                window.location.reload(); // Refresh the page on successful cancel
            } else {
                console.error('Failed to cancel subscription:', result.error);
            }
        } catch (error) {
            console.error('Failed to cancel subscription:', error);
        }
    }
};

const handlePasswordChange = async () => {
  if (!currentPassword || !newPassword) {
    alert("Please enter both current and new passwords.");
    return;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/change-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        userId: user.id, 
        currentPassword: currentPassword, 
        newPassword: newPassword 
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    if (result.message) {
      console.log(result.message);
      setPasswordChangeMessage(result.message);
      setCurrentPassword('');
      setNewPassword('');
    } else {
      setError('Failed to change password.');
    }
  } catch (error) {
    setError('Error changing password.');
  }
};

  if (loading) {
    return <div>Loading...</div>;
  }

  const isSubscribed = subscriptions.some(subscription => subscription.status === 'active' || subscription.status === 'trialing');


  return (
    <div className="settings-container">
      <h3>{t('subscription_settings')}</h3> {/* Subscription settings header */}
      {isSubscribed ? (
        <button onClick={CancelSubscription}>{t('cancel_subscription')}</button>
      ) : (
        <button onClick={() => window.location.href = '/pricing'}>{t('subscribe')}</button>
      )}
      {isSubscribed ? (
         <form action={`${process.env.REACT_APP_API_BASE_URL}/create-payment-update-session`} method="POST">
         <input type="hidden" name="customerId" value={user.stripeCustomerId} />
         <button type="submit">{t('payment_info')}</button>
       </form>
      ) : (
        <div></div>
      )}

      {/* Password and Security section */}
      <div className="password-section">
        <h3 className="password-header">{t('password_and_security')}</h3>
        <input
          type="password"
          className="password-input"
          placeholder={t('current_password')}
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
        <input
          type="password"
          className="password-input"
          placeholder={t('new_password')}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <button className="change-password" onClick={handlePasswordChange}>{t('change_password')}</button>
        {passwordChangeMessage && <p className="success-message">{passwordChangeMessage}</p>}
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
};


export default Settings;
