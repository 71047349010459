import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const ResetPassword = () => {
    const { resetToken } = useParams(); // This line extracts the token from the URL
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [validToken, setValidToken] = useState(false);
    const { t }= useTranslation();
    console.log('Reset Token:', resetToken);
    // Check token validity
    useEffect(() => {
        // Ensure resetToken is not undefined
        if (!resetToken) {
            setMessage(t('no_reset_token'));
            return;
        }

        fetch(`${process.env.REACT_APP_API_BASE_URL}/validate-reset-token/${resetToken}`)
            .then(response => {
                if (response.ok) {
                    setValidToken(true);
                } else {
                    setMessage(t('invalid_expired_token'));
                }
            })
            .catch(error => {
                console.error('Error validating reset token', error);
                setMessage(t('error_validating_token'));
            });
    }, [resetToken, t]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validToken) {
            setMessage(t('invalid_expired_token'));
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/reset-password/${resetToken}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ newPassword }),
            });

            if (response.ok) {
                setMessage(t('password_reset_success'));
            } else {
                setMessage(t('password_reset_fail'));
            }
        } catch (error) {
            console.error('Error resetting password', error);
            setMessage(t('error_resetting_password'));
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <input 
                    type="password" 
                    value={newPassword} 
                    onChange={(e) => setNewPassword(e.target.value)} 
                    placeholder={t('enter_new_password')} 
                    required 
                    disabled={!validToken}
                />
                <button type="submit" disabled={!validToken}>{t('reset_password_button')}</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default ResetPassword;
