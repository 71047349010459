import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from './AuthContext'; 
import styles from './Subscription.module.css';
import basicSubImage from './basic_sub.png'; // Adjust the path if necessary
import proSubImage from './pro_sub.png'; // Adjust the path if necessary
import reducedSubImage from './reduced_sub.png'; // Adjust the path if necessary
import fullSubImage from './premium_sub1.png'; // Adjust the path if necessary
import premSubImage from './ultra_sub.png'; // Adjust the path if necessary
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import hynoteClip from './hynote_clip2.mp4';
import { Switch } from 'antd';
const ReducedDisplay = ({ user }) => {
  const { t } = useTranslation();

  return (
    <section className={styles.container}>
      <div className={styles.product2}>
        <div className={styles.description}>
          <h3>{t('reduced_plan')}</h3>
          <h5>{t('reduced_price', { price: '2.00' })}</h5>
        </div>
        <img className={styles.productImage} src={reducedSubImage} alt="Basic Subscription" />
      </div>
      {user ? (
        <form action={`${process.env.REACT_APP_API_BASE_URL}/create-checkout-session`} method="POST">
          <input type="hidden" name="lookup_key" value="reducedPROD" />
          <input type="hidden" name="customer_id" value={user && user.stripeCustomerId ? user.stripeCustomerId : ''} />
          <div className={styles.buttonContainer}>
            <button id="checkout-and-portal-button" type="submit" className={styles.reducedbutton}>
              {t('subscribe')}
            </button>
          </div>
        </form>
      ) : (
        <div className={styles.buttonContainer}>
          <Link to="/login" className={styles.linkButton}>
            {t('subscribe')}
          </Link>
        </div>
      )}
    </section>
  );
};

const ReducedDisplayIndia = ({ user }) => {
  const { t } = useTranslation();

  return (
    <section className={styles.container}>
      <div className={styles.product2}>
        <div className={styles.description}>
          <h3>{t('reduced_plan')}</h3>
          <h5>{t('reduced_priceINDIA', { price: '2.00' })}</h5>
        </div>
        <img className={styles.productImage} src={reducedSubImage} alt="Basic Subscription" />
      </div>
      {user ? (
        <form action={`${process.env.REACT_APP_API_BASE_URL}/create-checkout-session`} method="POST">
          <input type="hidden" name="lookup_key" value="basicIndiaPROD" />
          <input type="hidden" name="customer_id" value={user && user.stripeCustomerId ? user.stripeCustomerId : ''} />
          <div className={styles.buttonContainer}>
            <button id="checkout-and-portal-button" type="submit" className={styles.reducedbutton}>
              {t('subscribe')}
            </button>
          </div>
        </form>
      ) : (
        <div className={styles.buttonContainer}>
          <Link to="/login" className={styles.linkButton}>
            {t('subscribe')}
          </Link>
        </div>
      )}
    </section>
  );

};

const ProductDisplay = ({ user }) => {
  const { t } = useTranslation();
  return (
    <section className={styles.container}>
      <div className={styles.product2}>
        <div className={styles.description}>
          <h3>{t('monthly_plan')}</h3>
          <h5>{t('monthly_price')}</h5>
        </div>
        <img className={styles.productImage} src={basicSubImage} alt="Basic Subscription" />
      </div>
      {user ? (
        <form action={`${process.env.REACT_APP_API_BASE_URL}/create-checkout-session`} method="POST">
          <input type="hidden" name="lookup_key" value="basicStandard" />
          <input type="hidden" name="customer_id" value={user && user.stripeCustomerId ? user.stripeCustomerId : ''} />
          <div className={styles.buttonContainer}>
            <button id="checkout-and-portal-button" type="submit" className={styles.basicbutton}>
              {t('subscribe')}
            </button>
          </div>
        </form>
      ) : (
        <div className={styles.buttonContainer}>
          <Link to="/login" className={styles.linkButton}>
            {t('subscribe')}
          </Link>
        </div>
      )}
    </section>
  );
};


const ProductDisplayIndia = ({ user }) => {
  const { t } = useTranslation();
  return (
    <section className={styles.container}>
      <div className={styles.product2}>
        <div className={styles.description}>
          <h3>{t('monthly_plan')}</h3>
          <h5>{t('monthly_priceINDIA')}</h5>
        </div>
        <img className={styles.productImage} src={basicSubImage} alt="Basic Subscription" />
      </div>
      {user ? (
        <form action={`${process.env.REACT_APP_API_BASE_URL}/create-checkout-session`} method="POST">
          <input type="hidden" name="lookup_key" value="basicStandardIndia" />
          <input type="hidden" name="customer_id" value={user && user.stripeCustomerId ? user.stripeCustomerId : ''} />
          <div className={styles.buttonContainer}>
            <button id="checkout-and-portal-button" type="submit" className={styles.basicbutton}>
              {t('subscribe')}
            </button>
          </div>
        </form>
      ) : (
        <div className={styles.buttonContainer}>
          <Link to="/login" className={styles.linkButton}>
            {t('subscribe')}
          </Link>
        </div>
      )}
    </section>
  );
};


const PremiumDisplay = ({ user }) => {
  const { t } = useTranslation();
  return (
    <section className={styles.container}>
      <div className={styles.product2}>
        <div className={styles.description}>
          <h3>{t('yearly_plan')}</h3>
          <h5>{t('yearly_price')}</h5>
        </div>
        <img className={styles.productImage} src={proSubImage} alt="Pro Subscription" />
      </div>
      {user ? (
        <form action={`${process.env.REACT_APP_API_BASE_URL}/create-checkout-session`} method="POST">
          <input type="hidden" name="lookup_key" value="basicYearlyNew" />
          <input type="hidden" name="customer_id" value={user && user.stripeCustomerId ? user.stripeCustomerId : ''} />
          <div className={styles.buttonContainer}>
            <button id="checkout-and-portal-button" type="submit" className={styles.probutton}>
              {t('subscribe')}
            </button>
          </div>
        </form>
      ) : (
        <div className={styles.buttonContainer}>
          <Link to="/login" className={styles.linkButton}>
            {t('subscribe')}
          </Link>
        </div>
      )}
    </section>
  );
};

const FreeTrialDisplay = ({ user }) => {
  const { t } = useTranslation();

  return (
    <section className={styles.container}>
      <div className={styles.product2}>

        {user ? (
          <form action={`${process.env.REACT_APP_API_BASE_URL}/create-trial-checkout-session`} method="POST">
            <input type="hidden" name="customer_id" value={user && user.stripeCustomerId ? user.stripeCustomerId : ''} />
            <div className={styles.buttonContainer}>
              <button type="submit" className={styles.freeTrialButton}>
                {t('tryfreenow')}
              </button>
              <p className={styles.footnoteText}>
                *{t('youwillbecharged')}
              </p>
            </div>
            
          </form>
          
        ) : (
          <div className={styles.buttonContainer}>
            <Link to="/login" className={styles.linkButton}>
              {t('tryfreenow')}
            </Link>
            <p className={styles.footnoteText}>
                *{t('youwillbecharged')}
              </p>
          </div>
        )}
      </div>
    </section>
  );
};


// Add a new component for the subscription description
const SubscriptionDescription = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.subscriptionDescription}>
      <br></br>
      <h1>{t('note_organization')}</h1>
      <br></br>
      <ul>
        <li>{t('unlimited_access')}</li>
      </ul>
    </div>
  );
};

// ... Rest of your component


const SuccessDisplay = ({ sessionId }) => {
  return (
    <section>
      <div className="product Box-root">
        <Logo />
        <div className="description Box-root">
          <h3>Subscription to starter plan successful!</h3>
        </div>
      </div>
      <form action="/create-portal-session" method="POST">
        <input
          type="hidden"
          id="session-id"
          name="session_id"
          value={sessionId}
        />
        <button id="checkout-and-portal-button" type="submit">
          Manage your billing information
        </button>
      </form>
    </section>
  );
};

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

const SubscribedDisplay = () => (
  <div className={styles.subscribedMessage}>
    <h3 className={styles.successHighlight}>You're all set!</h3>
    <p>Welcome aboard. You're ready to start using the app.</p>
  </div>
);
export default function Subscription() {

  // State variables for managing subscriptions, loading and error states

  
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isNonUS, setIsNonUS] = useState(false);
  const [isInIndia, setIsInIndia] = useState(false); // New state for checking if the user is in India
  const { t } = useTranslation();
  const [isCountryCheckComplete, setIsCountryCheckComplete] = useState(false); 
  const [isReadyToShowSubscriptions, setIsReadyToShowSubscriptions] = useState(false);
  // Function to check the user's country
  const checkCountry = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          try {
            const lat = position.coords.latitude;
            const lon = position.coords.longitude;
            const apiKey = process.env.REACT_APP_BIGDATACLOUD_API_KEY;
            const response = await axios.get(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lon}&localityLanguage=en&key=${apiKey}`);
            const data = response.data;
  
            setIsNonUS(data.countryCode !== 'US');
            setIsInIndia(data.countryCode === 'IN');
          } catch (error) {
            console.error('Error fetching location:', error);
          }
        },
        (error) => {
          console.warn('Error getting location:', error);
        }
      );
    } else {
      console.warn('Geolocation is not supported by this browser.');
    }
  };
  const toggleSubscriptionType = () => {
    setIsYearly(!isYearly); // Toggle between yearly and monthly
  };
  
  const [isYearly, setIsYearly] = useState(false); // Initial state as 'false' for monthly
  
  const handleFreeTrial = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/create-trial-checkout-session`, {
        customer_id: user && user.stripeCustomerId ? user.stripeCustomerId : '',
      });

      if (response.data.url) {
        window.location.href = response.data.url; // Redirect to Stripe's checkout
      } else {
        console.error('No redirect URL received');
      }
    } catch (error) {
      console.error('Error initiating free trial:', error);
    }
  };
  
  
    useEffect(() => {
      checkCountry();
    }, []);
  
  // Extract user data from AuthContext
  const { user } = useContext(AuthContext);

  // Function to fetch subscriptions for the current user
  const fetchSubscriptions = async () => {
    // Clear any previous errors
    setError(null);

    // Check if user data is available
    if (!user || !user.id) {
      console.warn('User data is not available yet.');
      setLoading(false); // Nothing to load without user info
      return;
    }

    // Attempt to fetch subscriptions
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user-subscriptions?userId=${user.id}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setSubscriptions(data.subscriptions); // Assuming the response structure
      
    } catch (error) {
      console.error('Error fetching subscriptions:', error);
      setError(error.toString());
    } finally {
      console.warn(user.id);
      setLoading(false); // Stop the loading indicator
    }
  };

  // useEffect to run when the component mounts and whenever 'user' changes
  useEffect(() => {
    console.log("Subscription component mounted or user updated.");
    
    // Ensure we actually have the user and user.id to avoid unnecessary requests
    if (user && user.id) {
      console.log(`Fetching subscriptions for user ID: ${user.id}`);
      fetchSubscriptions(); // Fetch subscriptions if we have the user info
    } else {
      console.warn("User data not available or incomplete.");
      setLoading(false); // Not technically loading if we don't have user info
    }
  }, [user]);  // Depend on 'user' so if it changes (like after a login), we refetch
  useEffect(() => {
    // Once the country check is complete and the component is mounted, allow the subscriptions to be shown
    if (isCountryCheckComplete) {
      setIsReadyToShowSubscriptions(true);
    }
  }, [isCountryCheckComplete]);
  // Handling UI based on different states
  // Conditional rendering based on country check
  if (loading || isNonUS === null || isInIndia === null) {
    return <div className={styles.loading}>...</div>; // Show loading or placeholder
  }
  if (error) {
    return <div className={styles.error}>Error: {error}</div>;
  }
  const PricingTableMonthly = ({ user, t }) => {
    return (
      <div className={styles.pricingTable}>
        {/* Header Row for Names and Images */}
        <div className={styles.tableRow}>
          <div className={styles.tableHeaderCell}></div> {/* Empty cell for alignment */}
          <div className={styles.tableCell}>
            <div>Basic Plan</div>
            <img src={basicSubImage} alt="Basic Subscription" className={styles.productImage} />
            <div className={styles.subSubText}>Best if you only need Voice Transcription</div>
          </div>
          <div className={styles.tableCell}>
            <div>Full Plan</div>
            <img src={fullSubImage} alt="Full Subscription" className={styles.productImage} />
            <div className={styles.subSubText}>Best All Around</div>
          </div>
          <div className={styles.tableCell}>
            <div>Premium Plan</div>
            <img src={premSubImage} alt="Premium Subscription" className={styles.productImage} />
            <div className={styles.subSubText}>Best for Content Farms and Professionals</div>
          </div>
        </div>
  
        {/* Row for Price */}
        <div className={styles.tableRow}>
          <div className={styles.tableHeaderCell}>Price</div>
          <div className={styles.tableCell}>$4/month</div>
          <div className={styles.tableCell}>$10/month</div>
          <div className={styles.tableCell}>$35/month</div>
        </div>
  
        {/* Row for Character Limit */}
        <div className={styles.tableRow}>
          <div className={styles.tableHeaderCell}>Character Limit</div>
          <div className={styles.tableCell}>10,000/day</div>
          <div className={styles.tableCell}>30,000/day</div>
          <div className={styles.tableCell}>100,000/day</div>
        </div>
  
        {/* Row for Voice Limit */}
        <div className={styles.tableRow}>
          <div className={styles.tableHeaderCell}>Voice Limit</div>
          <div className={styles.tableCell}>45 Min/day</div>
          <div className={styles.tableCell}>1.5 hours/day</div>
          <div className={styles.tableCell}>3 hours/day</div>
        </div>
  
      {/* Row for Subscribe Buttons */}
      <div className={styles.tableRow}>
        <div className={styles.tableHeaderCell}></div> {/* Empty cell for alignment */}

        {/* Subscribe Button for Basic Plan */}
        <div className={styles.tableCell}>
          {user ? (
            <form action={`${process.env.REACT_APP_API_BASE_URL}/create-checkout-session`} method="POST">
              <input type="hidden" name="lookup_key" value="basicStandard" />
              <input type="hidden" name="customer_id" value={user.stripeCustomerId} />
              <div className={styles.buttonContainer}>
                <button type="submit" className={styles.subscribeButton}>
                  {t('subscribe')}
                </button>
              </div>
            </form>
          ) : (
            <Link to="/login" className={styles.linkButton}>
              {t('subscribe')}
            </Link>
          )}
        </div>

        {/* Subscribe Button for Full Plan */}
        <div className={styles.tableCell}>
          {user ? (
            <form action={`${process.env.REACT_APP_API_BASE_URL}/create-checkout-session`} method="POST">
              <input type="hidden" name="lookup_key" value="full_monthly" />
              <input type="hidden" name="customer_id" value={user.stripeCustomerId} />
              <div className={styles.buttonContainer}>
                <button type="submit" className={styles.subscribeButton}>
                  {t('subscribe')}
                </button>
              </div>
            </form>
          ) : (
            <Link to="/login" className={styles.linkButton}>
              {t('subscribe')}
            </Link>
          )}
        </div>

        {/* Subscribe Button for Premium Plan */}
        <div className={styles.tableCell}>
          {user ? (
            <form action={`${process.env.REACT_APP_API_BASE_URL}/create-checkout-session`} method="POST">
              <input type="hidden" name="lookup_key" value="prem_sub" />
              <input type="hidden" name="customer_id" value={user.stripeCustomerId} />
              <div className={styles.buttonContainer}>
                <button type="submit" className={styles.subscribeButton}>
                  {t('subscribe')}
                </button>
              </div>
            </form>
          ) : (
            <Link to="/login" className={styles.linkButton}>
              {t('subscribe')}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

const PricingTableYearly = ({ user, t }) => {
  return (
    
    <div className={styles.pricingTable}>
      {/* Header Row for Names and Images */}
      <div className={styles.tableRow}>
        <div className={styles.tableHeaderCell}></div> {/* Empty cell for alignment */}
        <div className={styles.tableCell}>
          <div>Basic Plan</div>
          <img src={basicSubImage} alt="Basic Subscription" className={styles.productImage} />
          <div className={styles.subSubText}>Best if you only need Voice Transcription</div>
        </div>
        <div className={styles.tableCell}>
          <div>Full Plan</div>
          <img src={fullSubImage} alt="Full Subscription" className={styles.productImage} />
          <div className={styles.subSubText}>Best Offer!</div>
        </div>
        <div className={styles.tableCell}>
          <div>Premium Plan</div>
          <img src={premSubImage} alt="Premium Subscription" className={styles.productImage} />
          <div className={styles.subSubText}>Best for Content Farms and Professionals</div>
        </div>
      </div>
    
      {/* Row for Price */}
      <div className={styles.tableRow}>
        <div className={styles.tableHeaderCell}>Price</div>
        <div className={styles.tableCell}>$40/year(16% off)</div>
        <div className={styles.tableCell}>$80/year(33% off)</div>
        <div className={styles.tableCell}>$300/year(29% off)</div>
      </div>

      {/* Row for Character Limit */}
      <div className={styles.tableRow}>
        <div className={styles.tableHeaderCell}>Character Limit</div>
        <div className={styles.tableCell}>10,000/day</div>
        <div className={styles.tableCell}>30,000/day</div>
        <div className={styles.tableCell}>100,000/day</div>
      </div>

      {/* Row for Voice Limit */}
      <div className={styles.tableRow}>
        <div className={styles.tableHeaderCell}>Voice Limit</div>
        <div className={styles.tableCell}>45 Min/day</div>
        <div className={styles.tableCell}>1.5 hours/day</div>
        <div className={styles.tableCell}>3 hours/day</div>
      </div>

    {/* Row for Subscribe Buttons */}
    <div className={styles.tableRow}>
      <div className={styles.tableHeaderCell}></div> {/* Empty cell for alignment */}

      {/* Subscribe Button for Basic Plan */}
      <div className={styles.tableCell}>
        {user ? (
          <form action={`${process.env.REACT_APP_API_BASE_URL}/create-checkout-session`} method="POST">
            <input type="hidden" name="lookup_key" value="basic_yearly2" />
            <input type="hidden" name="customer_id" value={user.stripeCustomerId} />
            <div className={styles.buttonContainer}>
              <button type="submit" className={styles.subscribeButton}>
                {t('subscribe')}
              </button>
            </div>
          </form>
        ) : (
          <Link to="/login" className={styles.linkButton}>
            {t('subscribe')}
          </Link>
        )}
      </div>

      {/* Subscribe Button for Full Plan */}
      <div className={styles.tableCell}>
        
        {user ? (
          <form action={`${process.env.REACT_APP_API_BASE_URL}/create-checkout-session`} method="POST">
            <input type="hidden" name="lookup_key" value="full_yearly" />
            <input type="hidden" name="customer_id" value={user.stripeCustomerId} />
            <div className={styles.buttonContainer}>
              <button type="submit" className={styles.subscribeButton}>
                {t('subscribe')}
              </button>
            </div>
          </form>
        ) : (
          <Link to="/login" className={styles.linkButton}>
            {t('subscribe')}
          </Link>
        )}
      </div>

      {/* Subscribe Button for Premium Plan */}
      <div className={styles.tableCell}>
        {user ? (
          <form action={`${process.env.REACT_APP_API_BASE_URL}/create-checkout-session`} method="POST">
            <input type="hidden" name="lookup_key" value="prem_sub" />
            <input type="hidden" name="customer_id" value={user.stripeCustomerId} />
            <div className={styles.buttonContainer}>
              <button type="submit" className={styles.subscribeButton}>
                {t('subscribe')}
              </button>
            </div>
          </form>
        ) : (
          <Link to="/login" className={styles.linkButton}>
            {t('subscribe')}
          </Link>
        )}
      </div>
      
    </div>
  </div>
);
};

  

  if (subscriptions.length === 0) {
    return (
      <div className={styles.pageContainer}>
        <SubscriptionDescription />
        <FreeTrialDisplay user={user} />
        <div className={styles.switchContainer}>
            {!isYearly && <span>Monthly</span>}
            <Switch checked={isYearly} onChange={toggleSubscriptionType} />
            {isYearly && <span>Yearly</span>}
          </div>
            <br></br>
        <div className={styles.productDisplayContainer}>
          
          {isInIndia ? (
            // If the user is in India, show Reduced and Monthly plans
            <>
              <div className={styles.product}>
                <ReducedDisplayIndia user={user} />
              </div>
              <div className={styles.divider}></div>
              <div className={styles.product}>
                <ProductDisplayIndia user={user} />
              </div>
            </>
          ) : isNonUS ? (
            // If the user is outside the US but not in India, show all plans
            <>
              <div className={styles.product}>
                <ProductDisplay user={user} />
              </div>
              <div className={styles.divider}></div>
              <div className={styles.product}>
                <PremiumDisplay user={user} />
              </div>
              <div className={styles.divider}></div>
              <div className={styles.product}>
                <ReducedDisplay user={user} />
              </div>
              
            </>
          ) : (
            // If the user is in the US, show only the standard plans
            
            <>
            
      
               {isYearly ? (
                <PricingTableYearly user={user} t={t} />
              ) : (
                <PricingTableMonthly user={user} t={t} />
              )}
      {/* Include other components or content as needed */}
   
            </>
          )}
        </div>
        <br></br><br></br>
      </div>
    );
  }

  return (
    <div className={styles.pageContainer}>
      <div className="video-background">
          <video autoPlay loop muted className="background-video">
            <source src={hynoteClip} type="video/mp4" />
          </video>
        </div>
      <div className={styles.subscribedUserDisplay}>
        <br></br><br></br>
        <h3>{t('all_set')}</h3>
        <p>{t('welcome_message')}</p>
        <p><Link to="/Settings">{t('gotosettings')}</Link></p>
      </div>
    </div>
  );  
  
}





const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="14px"
    height="16px"
    viewBox="0 0 14 16"
    version="1.1"
  >
    <defs />
    <g id="Flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="0-Default"
        transform="translate(-121.000000, -40.000000)"
        fill="#E184DF"
      >
        <path
          d="M127,50 L126,50 C123.238576,50 121,47.7614237 121,45 C121,42.2385763 123.238576,40 126,40 L135,40 L135,56 L133,56 L133,42 L129,42 L129,56 L127,56 L127,50 Z M127,48 L127,42 L126,42 C124.343146,42 123,43.3431458 123,45 C123,46.6568542 124.343146,48 126,48 L127,48 Z"
          id="Pilcrow"
        />
      </g>
    </g>
  </svg>
);